import React, {useEffect, useState, useCallback} from 'react';
import AdminTable from './AdminTable';
import AdminTableTopBar from './AdminTableTopBar';
import {fetchAllAdmins} from '../AdminAPI';

const AdminContainer = () => {
    const [admins, setAdmins] = useState([]);
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [filterOptions, setFilterOptions] = useState({});
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const refetchAdminData = useCallback(async (options = {}) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllAdmins(pageNumber, 10, filterOptions);
            setAdmins(data.admins);
            setTotalPages(data.totalPages);
            setError('');
            setDataLoaded(true);
        } catch (error) {
            console.error('Error fetching all admins:', error.message);
            setError('Failed to fetch admin data.');
            setAdmins([]);
            setTotalPages(0);
        }
    }, [filterOptions, pageNumber]);

    useEffect(() => {
        refetchAdminData();
    }, [pageNumber, refreshFlag, refetchAdminData, totalPages, fetchTrigger, filterOptions]);

    return (
        <>
            <AdminTableTopBar
                refetchAdminData={refetchAdminData}
                setPageNumber={setPageNumber}
                setFilterOptions={setFilterOptions}
                setFetchTrigger={setFetchTrigger}
            />
            <AdminTable
                admins={admins}
                setAdmins={setAdmins}
                setRefreshFlag={setRefreshFlag}
                refetchAdminData={refetchAdminData}
                error={error}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                filterOptions={filterOptions}
                dataLoaded={dataLoaded}
            />
        </>
    );
};

export default AdminContainer;
