import {API1} from "../../../utils/api";

export const createFaq = async (faqData) => {
    try {
        const response = await API1.post('/faqs', faqData);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "An unexpected error occurred while creating the FAQ.");
    }
};

// Fetch All FAQDetails
export const fetchAllFaqs = async (pageNumber = 1, pageSize = 10, filterOptions = {}, handleError) => {
    try {
        const queryParams = new URLSearchParams({ ...filterOptions, pageNumber, pageSize }).toString();
        const response = await API1.get(`/faqs?${queryParams}`);
        if (response.status === 200) {
            return {
                faqs: response.data.docs,
                totalPages: Math.ceil(response.data.totalDocs / pageSize),
            };
        }
        throw new Error('Unexpected response status: ' + response.status);
    } catch (error) {
        const errorMessage = error?.message || "Failed to fetch FAQDetails. Please try again.";
        if (handleError) {
            handleError(errorMessage);
        }
        throw new Error(errorMessage);
    }
};


// Fetch FAQ by ID using query parameters
export const fetchFaqById = async (faqId) => {
    try {
        const queryParams = new URLSearchParams({ id: faqId }).toString();
        const response = await API1.get(`/faqs?${queryParams}`);
        if (response.status === 200 && response.data.docs.length > 0) {
            return response.data.docs[0];
        } else {
            throw new Error('FAQ not found.');
        }
    } catch (error) {
        throw new Error(error?.message || "Failed to fetch the FAQ details.");
    }
};

// Update FAQ
export const updateFaq = async (faqId, faqData) => {
    try {
        const response = await API1.put(`/faqs/${faqId}`, faqData);
        if (response.status !== 200) {
            throw new Error('Update failed due to server response');
        }
        return response.data;
    } catch (error) {
        console.error("Error updating FAQ:", error);
        throw new Error(error?.message || "An error occurred while updating the FAQ.");
    }
};


// Delete FAQ
export const deleteFaq = async (faqId) => {
    try {
        const response = await API1.delete(`/faqs/${faqId}`);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "Failed to delete the FAQ");
    }
};
