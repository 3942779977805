import React from 'react';
import {
    VStack, Box, Text, Stack, SimpleGrid, Badge, Button,
} from '@chakra-ui/react';
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import SectionHeading from "../../../../components/SectionHeading/SectionHeading";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../components/Styled/StyledButtons";
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import utils from "../../../../utils/commonFunctions";
import { useDisclosure } from "@chakra-ui/react";
import PdfViewerModal from "../../../../components/PdfViewer/PdfViewerModal";

const OrderViewForm = ({ formData }) => {
    const { closeDrawer } = useDrawer();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const {
        fullName,
        date,
        phoneNumber,
        email,
        addressDetails,
        uploads,
        additionalRequests,
        invoice,
        createdAt,
        updatedAt,
        status,  // Add status here
    } = formData;

    // Function to set badge color based on order status
    const getStatusColor = (status) => {
        switch (status) {
            case 'Pending':
                return 'yellow';
            case 'Processing':
                return 'blue';
            case 'Completed':
                return 'green';
            case 'Cancelled':
                return 'red';
            default:
                return 'gray';
        }
    };

    return (
        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
            <FormActionLabel formAction="view" formName="Order" />
            <Stack spacing={4} px={2} py={4}>
                <SectionHeading icon="fluent:apps-list-detail-24-filled" text="Order Information" />
                <SimpleGrid columns={1} gap={4} px={4}>
                    <Box>
                        <Text><strong>Full Name: </strong>{fullName}</Text>
                    </Box>
                    <Box>
                        <Text><strong>Date: </strong>{new Date(date).toLocaleDateString()}</Text>
                    </Box>
                    <Box>
                        <Text><strong>Phone Number: </strong>{phoneNumber}</Text>
                    </Box>
                    <Box>
                        <Text><strong>Email: </strong>{email}</Text>
                    </Box>
                    <Box>
                        <Text><strong>Address: </strong>{addressDetails || 'N/A'}</Text>
                    </Box>
                    <Box>
                        <Text><strong>Uploads: </strong>{uploads || 'N/A'}</Text>
                    </Box>
                    <Box>
                        <Text><strong>Additional Requests: </strong>{additionalRequests || 'N/A'}</Text>
                    </Box>
                    <Box>
                        <Text>
                            <strong>Status: </strong>
                            <Badge colorScheme={getStatusColor(status)}>{status}</Badge> {/* Add badge for order status */}
                        </Text>
                    </Box>
                    <Box>
                        <Text>
                            <strong>Invoice: </strong>
                            {invoice ? (
                                <>
                                    <Button onClick={onOpen} colorScheme="red" size="xs" >
                                        View Invoice
                                    </Button>
                                    <PdfViewerModal filePath={invoice} isOpen={isOpen} onClose={onClose} />
                                </>
                            ) : 'No invoice uploaded'}
                        </Text>
                    </Box>
                    <Box>
                        <Text><strong>Created At: </strong>{utils.DateFormat(createdAt)}</Text>
                    </Box>
                    <Box>
                        <Text><strong>Updated At: </strong>{utils.DateFormat(updatedAt)}</Text>
                    </Box>
                </SimpleGrid>
                <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }} mt={8} mb={8}>
                    <CustomAlert status="warning" message="View Only Mode" />
                    <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                        <CustomButton onClick={closeDrawer} type="cancel" showIcon={false}>
                            Close
                        </CustomButton>
                    </ButtonStack>
                </Box>
            </Stack>
        </VStack>
    );
};

export default OrderViewForm;
