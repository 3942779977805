import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { FormControl, Stack, Radio, RadioGroup, Box } from '@chakra-ui/react';
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";
import FieldErrorMessage from "../../../../components/FieldErrorMessage/FieldErrorMessage";
import { CustomButton } from "../../../../components/Styled/StyledButtons";
import Icon from "../../../../@core/components/icon";
import { ThemedStyledInput } from "../../../../components/Styled";

// Define validation schema for the form fields
const validationSchema = Yup.object({
    createdDate: Yup.date().nullable(), // For single date filter
    startDate: Yup.date().nullable(),
    endDate: Yup.date()
        .nullable()
        .min(Yup.ref('startDate'), 'End date cannot be before start date'),
});

const NotificationSearchFilter = ({ onFilterChange }) => {
    const showToast = useSingleToast();
    const [filterType, setFilterType] = useState('single');  // 'single' for createdDate, 'range' for date range

    return (
        <Formik
            initialValues={{ createdDate: '', startDate: '', endDate: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                // Adjust the filter to send only the relevant data based on filter type
                if (filterType === 'single') {
                    onFilterChange({ createdDate: values.createdDate });
                } else if (filterType === 'range') {
                    onFilterChange({ startDate: values.startDate, endDate: values.endDate });
                }
                setSubmitting(false);
            }}
        >
            {({ handleSubmit, resetForm }) => (
                <Form>
                    <Stack spacing={2} direction="row" align="center">
                        {/* Radio buttons to toggle between single date and date range */}
                        <RadioGroup onChange={setFilterType} value={filterType}>
                            <Stack direction="row">
                                <Radio value="single" colorScheme="green">Notification Date</Radio>
                                <Radio value="range" colorScheme="green">Date Range</Radio>
                            </Stack>
                        </RadioGroup>


                        {/* Show "Notification Date" input when "single" is selected */}
                        {filterType === 'single' && (
                            <FormControl flex="1">
                                <Field
                                    as={ThemedStyledInput}
                                    id="createdDate"
                                    name="createdDate"
                                    type="date"
                                    placeholder="Notification Date"
                                />
                                <FieldErrorMessage name="createdDate" />
                            </FormControl>
                        )}

                        {/* Show "Start Date" and "End Date" inputs when "range" is selected */}
                        {filterType === 'range' && (
                            <>
                                <FormControl flex="1">
                                    <Field
                                        as={ThemedStyledInput}
                                        id="startDate"
                                        name="startDate"
                                        type="date"
                                        placeholder="Start Date"
                                    />
                                    <FieldErrorMessage name="startDate" />
                                </FormControl>
                                <FormControl flex="1">
                                    <Field
                                        as={ThemedStyledInput}
                                        id="endDate"
                                        name="endDate"
                                        type="date"
                                        placeholder="End Date"
                                    />
                                    <FieldErrorMessage name="endDate" />
                                </FormControl>
                            </>
                        )}

                        {/* Search and Clear Buttons */}
                        <CustomButton
                            onClick={handleSubmit}
                            type="search"
                            icon={<Icon icon="bi:search" />}
                            showLabel={false}
                        >
                            Search
                        </CustomButton>
                        <CustomButton
                            type="reset"
                            onClick={() => {
                                resetForm();
                                onFilterChange({ createdDate: '', startDate: '', endDate: '' });
                            }}
                            icon={<Icon icon="radix-icons:reset" />}
                            showLabel={false}
                            tooltipLabel="Clear Filter"
                        >
                            Clear
                        </CustomButton>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
};

export default NotificationSearchFilter;
