import React from 'react'
import MessageContainer from './Components/MessageContainer'
import {Icon} from "@iconify/react";
import DefaultTemplate from "../../../components/DefaultTemplate/DefaultTemplate";

const Message = () => {
    return (
        <>
            <DefaultTemplate Content={ <MessageContainer/> } Title={"Feedback" } icon={<Icon icon="mdi:feedback" />}/>
        </>
    )
}

export default Message
