import React, { useCallback, useEffect, useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Badge,
    Spinner,
    Text,
} from '@chakra-ui/react';
import { fetchAllNotifications, fetchNotificationById } from "../NotificationAPI";
import NotificationViewForm from "./NotificationViewForm";
import { StyledTableContainer } from "../../../../components/Styled/StyledComponents";
import PaginationControls from "../../../../Context/PaginationControls/PaginationControls";
import { ViewButton } from "../../../../components/Styled/ActionButtons";
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const NotificationTable = ({ notifications, setNotifications, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions }) => {
    const { openDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const handleView = async (notification) => {
        try {
            const notificationData = await fetchNotificationById(notification._id);
            if (notificationData) {
                openDrawer({
                    title: 'View Notification',
                    component: NotificationViewForm,
                    props: { formData: notificationData },
                    key: `view-${notification._id}`,
                }, 'lg');
            } else {
                throw new Error('Notification data not found.');
            }
        } catch (error) {
            setError(error.message);
            showToast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const truncateText = (text, maxLength = 30) => {
        if (text.length <= maxLength) return text;
        return text.slice(0, maxLength) + '...';
    };

    const refetchNotificationData = useCallback(async (options = {}) => {
        try {
            setLoading(true); // Set loading to true before fetching data
            const data = await fetchAllNotifications(pageNumber, 10, { ...filterOptions, kind: 'AdminNotification' });
            setNotifications(data.notifications);
            setTotalPages(data.totalPages);
        } catch (error) {
            setError(error.message || "An error occurred.");
        } finally {
            setLoading(false); // Set loading to false after data is fetched
        }
    }, [filterOptions, pageNumber, setNotifications, setTotalPages]);

    useEffect(() => {
        refetchNotificationData(filterOptions);
    }, [pageNumber, refetchNotificationData, filterOptions, setRefreshFlag]);

    const formatHtmlContent = (content) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        return doc.body.textContent || "";
    };

    if (error) {
        return <Box textAlign="center" my="4">Failed to fetch notification data: {error}</Box>;
    }

    if (dataLoaded && Array.isArray(notifications) && notifications.length === 0) {
        return <Box textAlign="center" my="4">No records found.</Box>;
    }

    return (
        <>
            <StyledTableContainer>
                <PaginationControls pageNumber={pageNumber} setPageNumber={setPageNumber} totalPages={totalPages} />
                <Table variant="striped" width="100%" tableLayout="auto">
                    <Thead>
                        <Tr>
                            <Th>Type</Th>
                            <Th isNumeric></Th>
                            <Th minWidth="200px">Subject</Th>
                            <Th isNumeric></Th>
                            <Th isNumeric></Th>
                            <Th display={{ base: "none", md: "table-cell" }} minWidth="250px">Message</Th>
                            <Th isNumeric></Th>
                            <Th isNumeric></Th>
                            <Th>Date</Th>
                            <Th>Actions</Th>
                            <Th isNumeric></Th>
                            <Th isNumeric></Th>
                            <Th isNumeric></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            notifications.map((notification) => (
                                <Tr key={notification._id}>
                                    <Td>
                                        <Badge colorScheme="blue" variant="solid">
                                            {notification.type}
                                        </Badge>
                                    </Td>
                                    <Td isNumeric></Td>
                                    <Td minWidth="200px">{truncateText(notification.subject) || 'N/A'}</Td>
                                    <Td isNumeric></Td>
                                    <Td isNumeric></Td>
                                    <Td display={{ base: "none", md: "table-cell" }} minWidth="250px">{truncateText(formatHtmlContent(notification.message)) || 'N/A'}</Td>
                                    <Td isNumeric></Td>
                                    <Td isNumeric></Td>
                                    <Td>{new Date(notification.createdDate).toLocaleDateString()}</Td>
                                    <Td>
                                        <ViewButton onClick={() => handleView(notification)} />
                                    </Td>
                                    <Td isNumeric></Td>
                                    <Td isNumeric></Td>
                                    <Td isNumeric></Td>
                                </Tr>
                            ))
                        }
                    </Tbody>
                </Table>
            </StyledTableContainer>
            <ConfirmDialog isOpen={confirmDialog.isOpen} title={confirmDialog.title} message={confirmDialog.message} onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })} onConfirm={confirmDialog.onConfirm} />
        </>
    );
};

export default NotificationTable;
