import React, { useCallback } from 'react';
import { Flex, GridItem } from '@chakra-ui/react';
import {useDrawer} from "../../../../Context/DrawerContext/DrawerContext";
import {StyledGrid} from "../../../../components/Styled/StyledComponents";
import FaqSearchFilter from "./FaqSearchFilter";
import {ButtonStack, CustomButton} from "../../../../components/Styled/StyledButtons";
import Icon from "../../../../@core/components/icon";
import FaqAddForm from "./FaqAddForm";

const FaqTableTopBar = ({ refetchFaqData, setPageNumber, setFilterOptions, setFetchTrigger }) => {
    const { openDrawer } = useDrawer();

    const handleFilterChange = useCallback(async (newFilterOptions) => {
        setPageNumber(1);
        await new Promise(resolve => setTimeout(resolve, 0));
        setFilterOptions(newFilterOptions);
        setFetchTrigger(f => !f);
    }, [setPageNumber, setFilterOptions, setFetchTrigger]);



    return (
        <>
            <StyledGrid templateColumns="repeat(12, 1fr)">
                <GridItem colSpan={{ base: 10, md: 11 }}>
                    <FaqSearchFilter onFilterChange={handleFilterChange} />
                </GridItem>
                <GridItem colSpan={{ base: 12, md: 1 }}>
                    <ButtonStack direction="row">
                        <CustomButton
                            type="addNew"
                            showIcon={true}
                            showTooltip={true}
                            tooltipLabel="Add New FAQ"
                            icon={<Icon icon="majesticons:folder-plus-line" />}
                            onClick={() => openDrawer({
                                title: 'Add New FAQ',
                                component: FaqAddForm,
                                props: { refetchFaqData }
                            }, 'md')}
                        >
                            Add New
                        </CustomButton>
                    </ButtonStack>
                </GridItem>
            </StyledGrid>
            <Flex bg="white" mb={4}></Flex>
        </>
    );
};

export default FaqTableTopBar;
