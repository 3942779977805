import React from 'react';
import { Flex, Text, Link, useColorModeValue, Box } from '@chakra-ui/react';
import { Icon } from '@iconify/react';

const Footer = () => {
    const bgColor = useColorModeValue('gray.50', 'gray.800');
    const textColor = useColorModeValue('gray.600', 'white');
    const hoverTextColor = useColorModeValue('gray.700', 'gray.400');

    return (
        <Flex
            as="footer"
            w="full"
            bg={bgColor}
            color={textColor}
            position="fixed"
            bottom="0"
            left="0"
            p={{ base: '4', md: '6' }}
            px={{ base: '4', md: '8' }}
            py={{ base: '2', md: '4' }}
            justifyContent="space-between"
            alignItems="center"
            direction={{ base: 'column-reverse', md: 'row' }}
            textAlign="center"
            zIndex="banner"
            mt={{ base: '4', md: '8' }}
        >
            {/* Copyright information */}
            <Box
                display="flex"
                alignItems="center"
                justifyContent={{ base: 'center', md: 'start' }}
            >
                <Icon icon="ph:copyright" style={{ marginRight: '8px', verticalAlign: 'middle' }} />
                <Text fontSize="sm">2024 Emarket BW. All rights reserved.</Text>
            </Box>

            <Link
                href="https://weblogic.co.bw"
                isExternal
                _hover={{ textDecoration: 'none', color: hoverTextColor }}
                display="flex"
                alignItems="center"
                justifyContent={{ base: 'center', md: 'end' }}
                fontSize="sm"
            >
                Designed & Developed by Weblogic
            </Link>
        </Flex>
    );
};

export default Footer;
