import React from 'react'
import {Icon} from "@iconify/react";
import OrderContainer from "./Components/OrderContainer";
import DefaultTemplate from "../../../components/DefaultTemplate/DefaultTemplate";

const Orders = () => {
    return (
        <>
            <DefaultTemplate Content={ <OrderContainer/> } Title={"Orders" } icon={<Icon icon="mage:delivery-truck-fill" />}/>
        </>
    )
}

export default Orders
