import utils from "../../../utils/commonFunctions";
import {API1} from "../../../utils/api";


export const fetchAllNotifications = async (pageNumber = 1, pageSize = 10, filterOptions = {}, kind = 'AdminNotification') => {
    try {
        const userId = await utils.getUserId();
        filterOptions.userId = userId;
        filterOptions.kind = kind; // Pass the correct kind based on the portal

        const data = {
            filterOptions,
            pageNumber,
            pageSize
        };

        const response = await API1.post('/notification/list', data);

        if (response.status === 200 && response.data) {
            const { docs: notifications, totalDocs } = response.data.data;
            return {
                notifications,
                totalPages: Math.ceil(totalDocs / pageSize),
                totalDocs
            };
        } else {
            throw new Error('Unexpected response from the server while fetching notifications.');
        }
    } catch (error) {
        console.error("Error fetching notifications:", error);
        throw new Error(error?.message || 'Failed to fetch notifications. Please try again.');
    }
};






// FetchNotification by ID using query parameters
export const fetchNotificationById = async (notificationId, kind = 'AdminNotification') => {
    try {
        const userId = await utils.getUserId();
        const filterOptions = {
            userId,
            notificationId,
            kind
        };

        const data = {
            filterOptions,
            pageNumber: 1,
            pageSize: 1
        };

        const response = await API1.post('/notification/list', data);

        if (response.status === 200 && response.data.data.docs.length > 0) {
            return response.data.data.docs[0];
        } else {
            throw new Error('Notification not found.');
        }
    } catch (error) {
        console.error("Error fetching the notification details:", error);
        throw new Error(error?.message || 'Failed to fetch the notification details.');
    }
};










