import React, { useEffect, useState } from 'react';
import utils from "../../utils/commonFunctions";
import MainLayout from '../../layouts/MainLayout';
import { useNavigate } from "react-router-dom";
import { useIdleTimer } from 'react-idle-timer';
import { fetchAdminByEmail } from "../../pages/PrimaryFeatures/AdminUserManagement/AdminAPI";
import { PropagateLoader } from "react-spinners";
import useSingleToast from "../../hooks/UseSingleToast/UseSingleToast";
import {useDrawer} from "../../Context/DrawerContext/DrawerContext";

function PrivateRoute({ children, permissionsRequired = [] }) {
  const { closeDrawer } = useDrawer();
  const showToast = useSingleToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkPermissions = async () => {
      const token = localStorage.getItem('authToken');
      if (!token || !utils.isAuthenticated()) {
        //console.log("No token or not authenticated");
        navigate('/login');
        return;
      }

      const email = utils.getEmail(token);
      try {
        const adminData = await fetchAdminByEmail(email);
        //console.log("Admin Data:", adminData);
        const hasAllRequiredPermissions = permissionsRequired.every(permission => adminData.permissions.includes(permission));

        //console.log("Required Permissions:", permissionsRequired);
        //console.log("Has all required permissions:", hasAllRequiredPermissions);

        if (!hasAllRequiredPermissions) {
          if (window.location.pathname !== '/dashboard') {
            showToast({
              title: "Access Denied",
              description: "You do not have the necessary permissions to access this page.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            navigate('/dashboard');
            return;
          }
        }
      } catch (error) {
        console.error('Error fetching admin data:', error);
        navigate('/login');
      } finally {
        setLoading(false);
      }
    };

    checkPermissions();
  }, [permissionsRequired, navigate, showToast]);

  // Handle session expiry due to inactivity
  const handleOnIdle = () => {
    if (utils.isAuthenticated()) {
      closeDrawer();
      utils.logout(navigate, showToast, false);
      navigate('/login', { replace: true });
      showToast({
        title: "Session Expired",
        description: "Your session has expired due to inactivity. Please log in again.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * 10, // 10 minutes
    onIdle: handleOnIdle,
    debounce: 500,
  });

  // Check authentication status periodically
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!utils.isAuthenticated()) {
        closeDrawer();
        utils.logout(navigate, showToast, false);
        navigate('/login', { replace: true });
        showToast({
          title: "Session Expired",
          description: "Your session has expired. Please log in again.",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [navigate, showToast]);

  if (loading) {
    return (
        <div className="loader-container">
          <PropagateLoader size={8} color="green.300" />
        </div>
    );
  }


  if (utils.isAuthenticated()) {
    return <MainLayout>{children}</MainLayout>;
  } else {
    return null;
  }
}

export default PrivateRoute;
