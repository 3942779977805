import React from 'react';
import Routes from './Routes/Routes';
import ReusableDrawer from "./Context/DrawerContext/ReusableDrawer";
import { DrawerProvider } from "./Context/DrawerContext/DrawerContext";
import { PropagateLoader } from "react-spinners";
import {LoaderProvider, useLoader} from "./Context/LoaderContext/LoaderContext";
import {ErrorProvider} from "./Context/ErrorContext/ErrorContext";

const GlobalLoader = () => {
    const { loading } = useLoader();
    return loading ? (
        <div style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1000'
        }}>
            <PropagateLoader size={8} color="#59E81C" />
        </div>
    ) : null;
};

const App = () => {
    return (
        <ErrorProvider>
            <LoaderProvider>
                <DrawerProvider>
                    <GlobalLoader />
                    <ReusableDrawer />
                    <Routes />
                </DrawerProvider>
            </LoaderProvider>
        </ErrorProvider>
    );
};

export default App;
