import React, {useState} from 'react';
import {
  Flex,
  Stack,
  Icon,
  Text,
  Image,
  Box,
  Heading,
  FormControl,
  VStack,
  Link,
  FormLabel,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Button,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';
import EMARKETLogo from '../../../assests/images/emarket-logo.png';
import {  useNavigate } from 'react-router-dom';
import utils from "../../../utils/commonFunctions";
import { LiaUnlockAltSolid } from "react-icons/lia";
import {ViewIcon, ViewOffIcon} from "@chakra-ui/icons";
import {FaCheckCircle, FaTimesCircle} from "react-icons/fa";
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";

const CreatePasswordForm= () => {
  const [token, setToken] = useState(localStorage.getItem('userToken'));
  const [userId, setUserId] = useState(utils.getUserId(token));
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [conPassword, setConPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const navigate = useNavigate();
  const showToast = useSingleToast();
  const [lowerCaps, setLowerCaps] = useState(false);
  const [upperCaps, setUpperCaps] = useState(false);
  const [digit, setDigit] = useState(false);
  const [isLength, setIsLength] = useState(false);
  const [validPass, setValidPass] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const toggleShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const toggleShowNewPassword = () => setShowPassword(prevState => !prevState);
  const toggleShowConPassword = () => setShowConPassword(prevState => !prevState);

  const handleChangeOldPassword = (value) => {
    setOldPassword(value);
  };

  // Separate handlers for each input field
  const handleChangeNewPassword = (value) => {
    setNewPassword(value);
    validateNewPassword(value);
  };

  const validateNewPassword = (password) => {
    let isValid = utils.validatePassword(password);
    setDigit(isValid.digit);
    setUpperCaps(isValid.uppercase);
    setLowerCaps(isValid.lowercase);
    setIsLength(isValid.isLength);
    setValidPass(isValid.isValid);
  };
  const handleCreatePassword = async () => {
    if (!oldPassword || !newPassword || !conPassword) {
      showToast({
        title: 'All fields are required',
        description: 'Please fill in all the fields to create a new password.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!validPass) {
      showToast({
        title: 'Invalid Password',
        description: 'Your new password does not meet the required criteria.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (newPassword !== conPassword) {
      showToast({
        title: 'Password Mismatch',
        description: 'The new password and its confirmation do not match.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);
    try {
      const response = await utils.changePassword(userId, oldPassword, newPassword);
      if (response.status === 200) {
        localStorage.removeItem('userToken');
        showToast({
          title: 'Password Changed',
          description: 'Password updated successfully. Log in with your new password.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        navigate('/login');
      }
      else {
        showToast({
          title: 'Password Creation Failed',
          description: response.data.message || 'An unexpected error occurred. Please try again later.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      showToast({
        title: 'An Error Occurred',
        description: error?.message || 'An unexpected error occurred while changing your password.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return(<>

    <Stack
        bg='white'
        h={600}
        w={1000}
        rounded='xl'
        boxShadow='lg'
        direction={{base: 'column', md: 'row'}}
    >
      <Flex
          p={8} flex={1} align={'center'} justify={'center'}
      >
        <Stack
            spacing={4} w={'full'} maxW={'md'}
        >
          <VStack>
            <Box
                backgroundColor='green.500'
                borderRadius='50%'
                width='100px'
                height='100px'
                display='flex'
                justifyContent='center'
                alignItems='center'
            >
              <Icon as={LiaUnlockAltSolid} color='white' boxSize='50%'/>
            </Box>
            <Heading color='black' as='h6' size='lg'>
              Create New Password
            </Heading>
          </VStack>

          <Text fontSize="sm" color="gray.500" textAlign="center">
            Change password below.
          </Text>
          <FormControl id='oldPassword'>
            <FormLabel color='black'>Old Password</FormLabel>
            <InputGroup>
              <Input
                  type={showOldPassword ? 'text' : 'password'}
                  onChange={(e) => handleChangeOldPassword(e.target.value)}
              />
              <InputRightElement>
                <IconButton
                    aria-label={showOldPassword ? 'Hide password' : 'Show password'}
                    icon={showOldPassword ? <ViewOffIcon color='white' /> : <ViewIcon color='white' />}
                    onClick={toggleShowOldPassword}
                    bg='green.500'
                    _hover={{ bg: '#000000' }}
                    _active={{ bg: '#4699bc' }}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl id='newPassword'>
            <FormLabel color='black'>New Password</FormLabel>
            <InputGroup>
              <Input
                  type={showPassword ? 'text' : 'password'}
                  focusBorderColor='green.500'
                  onChange={(e)=>handleChangeNewPassword(e.target.value)}
              />
              <InputRightElement>
                <IconButton
                    aria-label={showPassword ? 'Hide password' : 'Show password'}
                    icon={showPassword ? <ViewOffIcon color='white' /> : <ViewIcon color='white' />}
                    onClick={toggleShowNewPassword}
                    bg='green.500'
                    _hover={{ bg: '#000000' }}
                    _active={{ bg: '#4699bc' }}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl id='newPassword'>
            <FormLabel color='black'>Confirm New Password</FormLabel>
            <InputGroup>
              <Input
                  type={showConPassword ? 'text' : 'password'}
                  focusBorderColor='green.500'
                  onChange={(e) => setConPassword(e.target.value)}
              />
              <InputRightElement>
                <IconButton
                    aria-label={showConPassword ? 'Hide password' : 'Show password'}
                    icon={showConPassword ? <ViewOffIcon color='white' /> : <ViewIcon color='white' />}
                    onClick={toggleShowConPassword} // This replaces handleClick
                    bg='green.500'
                    _hover={{ bg: '#000000' }}
                    _active={{ bg: '#4699bc' }}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Button
              _hover={{
                bg: 'green.500',
                color: 'white',
                borderColor: 'black',
              }}
              colorScheme='black'
              type='submit'
              variant='outline'
              isLoading={loading}
              loadingText="Verifying..."
              onClick={handleCreatePassword}
          >
            Create Password
          </Button>
          <Text fontSize="sm" textAlign="center">
            Need Help?{' '}
            <Link
                color={loading ? "gray.400" : "gray.1000"}
                onClick={!loading ? () => window.location.href = 'mailto:contactcenter@wuc.bw' : undefined}
                _hover={{
                  textDecoration: loading ? 'none' : 'underline'
                }}
                cursor={loading ? 'not-allowed' : 'pointer'}
            >
              Contact Super Administrator
            </Link>
          </Text>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Flex direction={'column'} justify={'center'} align={'center'} w={'full'} h={'full'}>
          <Image
              alt={'ResetPassword Image'}
              src={EMARKETLogo}
              objectFit={'contain'}
              h={200}
              w={200}

              display={{base: 'none', md: 'flex'}}
          />
          <List textAlign={"start"} spacing={3} color={"black"} p={4} borderRadius={'2xl'} bg={"gray.100"}>
            <ListItem>
              <ListIcon
                  as={isLength ? FaCheckCircle : FaTimesCircle}
                  color={isLength ? "green.500" : "red.500"}
              />
              Password must contain atleast 8 characters
            </ListItem>
            <ListItem>
              <ListIcon
                  as={upperCaps ? FaCheckCircle : FaTimesCircle}
                  color={upperCaps ? "green.500" : "red.500"}
              />
              Password must contain uppercase letter
            </ListItem>
            <ListItem>
              <ListIcon
                  as={lowerCaps ? FaCheckCircle : FaTimesCircle}
                  color={lowerCaps ? "green.500" : "red.500"}
              />
              Password must contain lowercase letter
            </ListItem>
            <ListItem>
              <ListIcon
                  as={digit ? FaCheckCircle : FaTimesCircle}
                  color={digit ? "green.500" : "red.500"}
              />
              Password must contain a digit
            </ListItem>
          </List>
        </Flex>

      </Flex>
    </Stack>
  </>)

}


export default CreatePasswordForm;
