import React, {useEffect, useState} from 'react';
import {
    Viewer,
    Worker
} from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PdfViewer = ({ PdfData }) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [file,setFile]=useState(PdfData)
    useEffect(() => {
        if(PdfData){
            setFile(PdfData)
        }

    }, [PdfData]);

    return (
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
            <div style={{ height: '750px' }}>
                {file && <Viewer fileUrl={file} plugins={[defaultLayoutPluginInstance]} /> }
            </div>
        </Worker>
    );
};

export default PdfViewer;
