import {
    Alert,
    AlertDescription, AlertIcon,
    Button,
    Modal, ModalBody,
    ModalContent,
    ModalOverlay, Text,

} from "@chakra-ui/react";

import {useNavigate} from "react-router-dom";

function MigratedAlert({isOpen,onClose,message}) {
    const navigate=useNavigate()
    const backToLogin = () => {
        onClose()
        navigate('/login')
    }

    return (
        <>
            <Modal isCentered   closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent p={0}>
                    <ModalBody bg={'transparent'} boxShadow={'lg'} p={0}>
                        <Alert
                            status='warning'
                            variant='subtle'
                            flexDirection='column'
                            alignItems='center'
                            justifyContent='center'
                            textAlign='center'
                            height='200px'
                            borderRadius={'xl'}
                            p={4}
                            w={'full'}
                        >
                            <AlertIcon boxSize='40px' mr={0} />

                            <AlertDescription maxWidth='sm'>
                                <Text> {message}</Text>
                                <Text my={2} fontWeight={'semibold'}>Password reset link has been sent to your email.</Text>
                                <Button variant={'ghost'} colorScheme={'blue'} onClick={backToLogin}>
                                    Back to login
                                </Button>
                            </AlertDescription>

                        </Alert>

                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
export default MigratedAlert