import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/Header';
import MiniStatistics from '../../components/MiniStatistics/MiniStatistics';
import { Box, SimpleGrid, Flex } from '@chakra-ui/react';
import {
  MdLocalShipping,
  MdPendingActions,
  MdSync,
  MdDoneAll, MdCancel,
} from 'react-icons/md';
import AnalyticsDashboard from '../../components/AnalyticsDashboard/AnalyticsDashboard';
import Footer from '../../components/Footer/Footer';
import { API1 } from '../../utils/api';
import utils from '../../utils/commonFunctions';

const Dashboard = () => {
  const [orderStats, setOrderStats] = useState({
    totalOrders: 0,
    pendingOrders: 0,
    processingOrders: 0,
    completedOrders: 0,
  });
  const [error, setError] = useState('');

  const fetchOrderStats = async () => {
    try {
      const response = await API1.get('/orders/statistics');
      setOrderStats(response.data.data || {});
    } catch (error) {
      utils.sendMessageToBugNinja("Error fetching order statistics:", error.message, error);
      setError('Failed to fetch order statistics.');
    }
  };

  useEffect(() => {
    fetchOrderStats();
  }, []);

  return (
      <div>
        <Header />
        <Flex
            bg='gray.50'
            minH={'100vh'}
            alignItems='center'
            justifyContent='center'
        >
          <Box
              maxW='100%'
              mx={'auto'}
              px={{ base: '10', md: '0' }}
              pt={{ base: 10, sm: 0, md: 0 }}
          >
            <SimpleGrid columns={{ base: 1, md: 2, lg: 5 }} spacing={{ base: 5, lg: 5 }}>
              <MiniStatistics
                  title={'All Orders Form'}
                  stat={orderStats.totalOrders}
                  icon={<MdLocalShipping size={'3em'} color='#59E81C' />}
              />
              <MiniStatistics
                  title={'Pending Orders Form'}
                  stat={orderStats.pendingOrders}
                  icon={<MdPendingActions size={'3em'} color='#59E81C' />}
              />
              <MiniStatistics
                  title={'Processing Orders Form'}
                  stat={orderStats.processingOrders}
                  icon={<MdSync size={'3em'} color='#59E81C' />}
              />
              <MiniStatistics
                  title={'Completed Orders Form'}
                  stat={orderStats.completedOrders}
                  icon={<MdDoneAll size={'3em'} color='#59E81C' />}
              />
              <MiniStatistics
                  title={'Cancelled Orders Form'}  // Added cancelled orders card
                  stat={orderStats.cancelledOrders}
                  icon={<MdCancel size={'3em'} color='#59E81C' />}
              />
            </SimpleGrid>
            <AnalyticsDashboard />
          </Box>
        </Flex>
        <Footer />
      </div>
  );
};

export default Dashboard;
