import React, {useCallback, useEffect, useState} from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Badge,
} from '@chakra-ui/react';
import MessageViewForm from './MessageViewForm';
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import { EditButton, ViewButton } from "../../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../../components/Styled/StyledComponents";
import PaginationControls from "../../../../Context/PaginationControls/PaginationControls";
import { fetchMessageById, fetchAllMessages } from "../MessageAPI";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const MessageTable = ({ messages, setMessages, pageNumber, dataLoaded, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions }) => {
    const { openDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [error, setError] = useState('');

    /*const handleEdit = (messageId) => {
        openDrawer({
            title: 'Edit Message',
            component: MessageEditForm,
            props: {
                messageId: messageId,
                refetchMessageData: refetchMessageData,
                setRefreshFlag: setRefreshFlag,
            },
            key: `edit-${messageId}`,
        }, 'md');
    };*/

    const handleView = async (message) => {
        try {
            const updatedMessageData = await fetchMessageById(message._id);
            openDrawer({
                title: 'View Message',
                component: MessageViewForm,
                props: {
                    formData: updatedMessageData,
                },
                key: `view-${message._id}`,
            }, 'md');
        } catch (error) {
            console.error("Failed to fetch message data:", error);
            setError("Failed to fetch message data. Please try again later.");
        }
    };

    const refetchMessageData = useCallback(async (options = {}) => {
        setError('');
        try {
            const data = await fetchAllMessages(pageNumber, 10, filterOptions);
            setMessages(data.messages);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error("Fetch messages error:", error.message);
        }
    }, [filterOptions, pageNumber, setMessages, setTotalPages]);

    useEffect(() => {
        refetchMessageData();
    }, [refetchMessageData, pageNumber, filterOptions]);

    if (error) {
        return <Box textAlign="center" my="4">Failed to fetch message data: {error}</Box>;
    }

    if (dataLoaded && Array.isArray(messages) && messages.length === 0) {
        return <Box textAlign="center" my="4">No records found.</Box>;
    }

    // Function to truncate long comments
    const truncateText = (text, maxLength = 30) => {
        if (text.length <= maxLength) return text;
        return text.slice(0, maxLength) + '...';
    };

    return (
        <>
            <StyledTableContainer>
                <PaginationControls
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    totalPages={totalPages}
                />
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>Full Name</Th>
                            <Th>Email</Th>
                            <Th>Phone</Th>
                            <Th>Subject</Th>
                            <Th>Comments</Th>
                            {/*<Th>Status</Th>*/}
                            <Th>Actions</Th>
                            <Th isNumeric></Th>
                            <Th isNumeric></Th>
                            <Th isNumeric></Th>
                            <Th isNumeric></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {messages.map((message) => (
                            <Tr key={message._id}>
                                <Td>{message.fullName}</Td>
                                <Td>{message.email}</Td>
                                <Td>{message.phoneNumber}</Td>
                                <Td>{truncateText(message.subject)}</Td>
                                <Td>{truncateText(message.comments)}</Td>
                                {/*<Td>
                                    <Badge colorScheme={message.status === 'Resolved' ? 'green' : message.status === 'Reviewed' ? 'yellow' : 'red'} ml={2} variant="solid">
                                        {message.status}
                                    </Badge>
                                </Td>*/}
                                <Td>
                                    <ViewButton onClick={() => handleView(message)} />
                                    {/*<EditButton onClick={() => handleEdit(message._id)} />*/}
                                </Td>
                                <Td isNumeric></Td>
                                <Td isNumeric></Td>
                                <Td isNumeric></Td>
                                <Td isNumeric></Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>
        </>
    );
};

export default MessageTable;
