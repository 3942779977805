import React, { useCallback, useEffect, useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Badge,
} from '@chakra-ui/react';
import OrderEditForm from './OrderEditForm';
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";
import { useError } from "../../../../Context/ErrorContext/ErrorContext";
import { deleteOrder, fetchAllOrders, fetchOrderById } from "../OrderAPI";
import { DeleteButton, EditButton, ViewButton } from "../../../../components/Styled/ActionButtons";
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import { StyledTableContainer } from "../../../../components/Styled/StyledComponents";
import PaginationControls from "../../../../Context/PaginationControls/PaginationControls";
import OrderViewForm from "./OrderViewForm";

const OrderTable = ({ orders, setOrders, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions }) => {
    const { handleError } = useError();
    const { openDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [error, setError] = useState('');
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const handleEdit = (orderId) => {
        openDrawer({
            title: 'Edit Order',
            component: OrderEditForm,
            props: {
                orderId: orderId,
                refetchOrderData: refetchOrderData,
                setRefreshFlag: setRefreshFlag,
            },
            key: `edit-${orderId}`,
        }, 'md');
    };

    const handleView = async (Order) => {
        try {
            const OrderData = await fetchOrderById(Order._id);
            if (OrderData) {
                openDrawer({
                    title: 'View Order',
                    component: OrderViewForm,
                    props: { formData: OrderData },
                    key: `view-${Order._id}`,
                }, 'lg');
            } else {
                throw new Error('Order data not found.');
            }
        } catch (error) {
            setError(error.message);
            showToast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const refetchOrderData = useCallback(async (options = {}) => {
        try {
            const data = await fetchAllOrders(pageNumber, 10, filterOptions, handleError);
            setOrders(data.orders);
            setTotalPages(data.totalPages);
        } catch (error) {
            handleError(error.message || "An error occurred.");
        }
    }, [filterOptions, handleError, pageNumber, setOrders, setTotalPages]);

    useEffect(() => {
        refetchOrderData(filterOptions);
    }, [pageNumber, refetchOrderData, filterOptions, setRefreshFlag]);

    if (error) {
        return <Box textAlign="center" my="4">Failed to fetch order data: {error}</Box>;
    }

    if (dataLoaded && Array.isArray(orders) && orders.length === 0) {
        return <Box textAlign="center" my="4">No records found.</Box>;
    }

    return (
        <>
            <StyledTableContainer>
                <PaginationControls
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    totalPages={totalPages}
                />
                <Table variant="striped" size="md">  {/* Added a consistent size */}
                    <Thead>
                        <Tr>
                            <Th>#</Th>
                            <Th>Full Name</Th>
                            <Th>Email</Th>
                            <Th>Phone Number</Th>
                            <Th>Selected Date</Th>
                            <Th>Status</Th>
                            <Th>Actions</Th>
                            <Th isNumeric></Th>
                            <Th isNumeric></Th>
                            <Th isNumeric></Th>
                            <Th isNumeric></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            orders.map((Order, index) => (
                                <Tr key={index}>
                                    <Td>O0{index + 1}</Td>
                                    <Td>{Order.fullName}</Td>
                                    <Td>{Order.email}</Td>
                                    <Td>{Order.phoneNumber}</Td>
                                    <Td>{new Date(Order.date).toLocaleDateString()}</Td>
                                    <Td>
                                        <Badge variant="solid" colorScheme={
                                            Order.status === 'Completed' ? "cyan" :
                                                Order.status === 'Pending' ? "yellow" :
                                                    Order.status === 'Processing' ? "blue" :
                                                        Order.status === 'Cancelled' ? "red" :
                                                            "gray"
                                        }>
                                            {Order.status}
                                        </Badge>
                                    </Td>
                                    <Td>
                                        <ViewButton onClick={() => handleView(Order)} />
                                        <EditButton onClick={() => handleEdit(Order._id)} />
                                        {/* Uncomment the delete button if needed */}
                                        {/*<DeleteButton onClick={() => handleDelete(Order._id)} />*/}
                                    </Td>

                                    <Td isNumeric></Td>
                                    <Td isNumeric></Td>
                                    <Td isNumeric></Td>
                                    <Td isNumeric></Td>
                                </Tr>
                            ))
                        }
                    </Tbody>
                </Table>
            </StyledTableContainer>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                title={confirmDialog.title}
                message={confirmDialog.message}
                onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                onConfirm={confirmDialog.onConfirm}
            />
        </>
    );
};

export default OrderTable;
