import React from 'react'
import NotificationContainer from './Components/NotificationContainer'
import {Icon} from "@iconify/react";
import DefaultTemplate from "../../../components/DefaultTemplate/DefaultTemplate";

const Notification = () => {
    return (
        <>
            <DefaultTemplate Content={ <NotificationContainer/> } Title={"Notifications" } icon={<Icon icon="f7:bell-fill" />}/>
        </>
    )
}

export default Notification
