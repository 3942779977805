import React, { useState } from 'react';
import {
  Flex,
  Input,
  Stack,
  Icon,
  Image,
  Button,
  Box,
  Heading,
  FormLabel,
  FormControl,
  VStack,
  HStack,
  Link,
  InputGroup,
  InputRightElement,
  IconButton, useDisclosure,
} from '@chakra-ui/react';
import { FaCircleUser } from 'react-icons/fa6';
import { PropagateLoader } from 'react-spinners';
import EMARKETLOGO from '../../../assests/images/emarket-logo.png';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import utils from "../../../utils/commonFunctions";
import { LiaUnlockAltSolid } from "react-icons/lia";
import enums from "wuc-library/enums";
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";
import MigratedAlert from "./MigratedAlert";

const LoginForm = () => {
  //const { executeRecaptcha } = useGoogleReCaptcha();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const showToast = useSingleToast();
  const [isMigrated, setMigrated] = useState(false);
  const [message, setMessage] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure()
  
  const toggleShowPassword = () => setShowPassword(prevState => !prevState);

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!email || !password) {
      showToast({
        title: 'Missing Credentials',
        description: 'Both email and password are required to log in.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);

    try {
      const response = await utils.login(email, password);
      if(!response){
        showToast({
          title: 'Login Error',
          description: 'Error occured while logging in.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        navigate('/login');
        return
      }
      if (response.status === 202) {
        await utils.forgotPassword(email)
        setLoading(false); // Stop loading here as well
        setMigrated(true)
        onOpen()
        setMessage(response.data.message)
        return;
      }
      if(response?.data.status === 401){
        showToast({
          title: 'Invalid Credentials',
          description: response?.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        navigate('/login');
        return
      }

        const { token } = response.data;
        utils.setToken('userToken', token);

        const role = utils.getRole(token);
        if(role!=='admin'){
          showToast({
            title: 'Access Denied',
            description: 'Only admins can access the admin panel.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          navigate('/login');
          return
        }

          const isVerified = utils.getIsVerified(token);
          const isFirstTimeLogin = utils.getIsFirstTimeLogin(token);
          const requirePasswordChange = utils.getRequirePasswordChange(token);
        if (!isVerified) {
          await requestOTPAndNavigate(email);
          return
        }
        if(isFirstTimeLogin || requirePasswordChange){
          navigateToCreatePassword();
          return
        }
        successfulLoginFlow(token);
    } catch (error) {

      console.log(error)
      if(error.code==='ERR_NETWORK'){
        showToast({
          title: error.message,
          description:"Please try again later",
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      else {
        showToast({
          title: 'Login Error',
          description: error?.message || 'Server error. Please try again later.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } finally {
      setLoading(false);
    }
  };


  const successfulLoginFlow = (token) => {
    showToast({
      title: 'Login Successful',
      description: 'Welcome back! You have been successfully logged in.',
      status: 'success',
      duration: 1500,
      isClosable: true,
    });
    utils.setToken('authToken', `Bearer ${token}`);
    localStorage.removeItem('userToken');
    navigate('/dashboard');
  };

  const adminConditionalRedirects = (isVerified, isFirstTimeLogin, requirePasswordChange) => {
    console.log(isVerified,isFirstTimeLogin,requirePasswordChange)
    if (!isVerified) {
      requestOTPAndNavigate(email);
    }
    if( isFirstTimeLogin){
      navigateToCreatePassword();
    }
    if (requirePasswordChange) {
      navigateToCreatePassword();
    }
  };

  const requestOTPAndNavigate = async (email) => {
    try {
      await utils.requestNewOTP(email, enums.OTPUsage.REGISTRATION);
      showToast({
        title: 'OTP Verification Required',
        description: 'An OTP has been sent to your email. Please enter it on the next screen to verify your account.',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
      navigate('/otp');
    } catch (otpError) {
      console.error('Error requesting new OTP:', otpError);
      showToast({
        title: 'OTP Request Error',
        description: 'Failed to send OTP. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const navigateToCreatePassword = () => {
    showToast({
      title: 'Password Change Required',
      description: 'You are required to change your password. Please reset now.',
      status: 'warning',
      duration: 5000,
      isClosable: true,
    });
    navigate('/create-password');
  };

  return (
      <>
        <Stack
            bg="white"
            h={{ base: 'auto', md: 550 }}
            w={{ base: 'full', md: 550 }}
            rounded="xl"
            boxShadow="lg"
            direction={{ base: 'column', md: 'row' }}
        >
          {isMigrated && (
              <MigratedAlert isOpen={isOpen} onClose={onClose} message={message} />
          )}
          <Flex p={8} flex={1} align={'center'} justify={'center'}>
            <Stack spacing={4} w={'full'} maxW={'md'}>
              <Flex align={'center'} justify="center" direction={{ base: 'column', md: 'column' }}>
                <VStack>
                  {/*<Box
                      backgroundColor="green.500"
                      borderRadius="50%"
                      width={{ base: '75px', md: '100px' }}
                      height={{ base: '75px', md: '100px' }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                  >
                    <Icon as={LiaUnlockAltSolid} color="white" boxSize="50%" />
                  </Box>*/}
                  <Flex flex={1} justifyContent="center" alignItems="center">
                    <Image
                        alt={'Login'}
                        src={EMARKETLOGO}
                        objectFit={'contain'}
                        h={{ base: 70, md: 120 }}
                        w={{ base: 70, md: 120 }}
                        mt={{ base: 8, md: 0 }}
                    />
                  </Flex>
                  <Heading color="black" as="h6" size={{ base: 'md', md: 'lg' }}>
                    Admin Panel
                  </Heading>
                </VStack>
              </Flex>
              <FormControl id="email">
                <FormLabel color="black">Email</FormLabel>
                <InputGroup>
                  <Input
                      type={'text'}
                      focusBorderColor="green.500"
                      onChange={(e) => setEmail(e.target.value)}
                  />
                  <InputRightElement>
                    <IconButton
                        icon={<FaCircleUser color="white" />}
                        bg="green.500"
                        _hover={{ bg: '#000000' }}
                        _active={{ bg: '#4699bc' }}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl id="password">
                <FormLabel color="black">Password</FormLabel>
                <InputGroup>
                  <Input
                      type={showPassword ? 'text' : 'password'}
                      focusBorderColor="green.500"
                      onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputRightElement>
                    <IconButton
                        aria-label={showPassword ? 'Hide password' : 'Show password'}
                        icon={showPassword ? <ViewOffIcon color="white" /> : <ViewIcon color="white" />}
                        onClick={toggleShowPassword}
                        bg="green.500"
                        _hover={{ bg: '#000000' }}
                        _active={{ bg: '#4699bc' }}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Stack spacing={6}>
                {loading ? (
                    <Button bg={'green.500'} _hover={{ bg: 'green.300' }}>
                      <PropagateLoader size={8} color="white" />
                    </Button>
                ) : (
                    <Button
                        _hover={{
                          bg: 'green.500',
                          color: 'white',
                          borderColor: 'black',
                        }}
                        colorScheme="black"
                        type="submit"
                        variant="outline"
                        onClick={handleLogin}
                    >
                      Sign in
                    </Button>
                )}
                <HStack spacing={8} justifyContent="space-between" alignItems="center">
                  <Link
                      as={RouterLink}
                      to="/forgot-password"
                      style={{ textDecoration: 'none', color: 'black' }}
                      _hover={{ textDecoration: 'none' }}
                  >
                    Forgot Password
                  </Link>
                  <Link
                      href="https://emarket.co.bw/"
                      color="black"
                      isExternal
                      _hover={{ textDecoration: 'none' }}
                  >
                    View the site
                  </Link>
                </HStack>
              </Stack>
            </Stack>
          </Flex>

        </Stack>

      </>
  );


};

export default LoginForm;
