import React, { useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../css/TextEditor.css';

const TextEditor = ({ value, onChange, error, touched, height="300px" }) => {
    const [isFocused, setIsFocused] = useState(false);

    // Define the colors
    const borderColor = isFocused ? '#59E81C' : '#E2E8F0';

    return (
        <Box
            className="custom-quill"
            style={{
                border: `2px solid ${borderColor}`,
                borderRadius: '5px',
                transition: 'border-color 0.2s',
            }}

            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
        >
            <ReactQuill
                theme="snow"
                value={value}
                onChange={onChange}
                style={{ height, marginBottom: '10px' }}
            />
            {touched && error && (
                <Text color="red.500" fontSize="sm" mt="8px">{error}</Text>
            )}
        </Box>
    );
};

export default TextEditor;
