import {Outlet, Navigate} from "react-router-dom";
import utils from "../../utils/commonFunctions";
function AuthRouter() {
  //console.log(utils.isAuthenticated())
  if (utils.isAuthenticated()) {
    return <Navigate to={'/dashboard'}/>
  }
  return <Outlet />}

export default AuthRouter;
