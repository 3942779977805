import React from 'react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, useTheme, useBreakpointValue } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { ChevronRightIcon } from '@chakra-ui/icons';

const CustomBreadcrumb = () => {
  const location = useLocation();
  const theme = useTheme();
  const fontSize = useBreakpointValue({ base: 'sm', md: 'md' });

  // Function to convert URL path to human-readable text
  const formatText = (text) => {
    return text
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  // Function to determine the current page based on the route
  const getCurrentPage = (path) => {
    const routes = {
      dashboard: 'Dashboard',
      'new-connection': 'New Connection',
      'pay-bill-online': 'Pay Bill Online',
      'view-account-balance': 'View Account Balance',
      'submit-meter-reading': 'Submit Meter Reading',
      'request-monthly-bill': 'Request Monthly Bill',
      'report-leak': 'Report Leak',
      'manage-utility-contracts': 'Manage Utility Contracts',
      'manage-beneficiary': 'Manage Beneficiary',
      'know-your-customer': 'Know Your Notification',
      'update-profile': 'Update Profile',
      'change-password': 'Change Password',
    };
    const lastSegment = path.split('/').pop();
    return routes[lastSegment] || formatText(lastSegment);
  };

  const currentPage = getCurrentPage(location.pathname);

  return (
      <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon boxSize={useBreakpointValue({ base: '2', md: '4' })} color="brand.500" />}
          borderRadius="md"
      >
        <BreadcrumbItem>
          <BreadcrumbLink
              href="#"
              textDecoration="none"
              fontSize="14px"
              fontWeight="600"
          >
            Dashboard
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink
              href="#"
              fontSize="14px"
              fontWeight="normal"
          >
            {currentPage}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
  );
};

export default CustomBreadcrumb;
