import React, { createContext, useContext, useState } from 'react';
// import utils from '../../utils/commonFunctions';

export const ErrorContext = createContext();

export const useError = () => useContext(ErrorContext);

export const ErrorProvider = ({ children }) => {
    const [error, setError] = useState(null);

    const handleError = (message) => {
        setError(message);
        setTimeout(() => setError(null), 5000);
    };

    return (
        <ErrorContext.Provider value={{ error, handleError }}>
            {children}
            {error && <div style={{ position: 'absolute', top: 0, width: '100%', backgroundColor: 'red', color: 'white', textAlign: 'center' }}>
                {error}
            </div>}
        </ErrorContext.Provider>
    );
};