import React, { useState, useEffect } from 'react';
import {
  Flex,
  Stack,
  Box,
  chakra,
  Tooltip,
  IconButton,
  Badge,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';
import { AiFillEdit, AiOutlineEye } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';
import Chart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';
import { API1 } from "../../utils/api";
import utils from '../../utils/commonFunctions';

const AnalyticsDashboard = () => {
  const [orderStats, setOrderStats] = useState([]);
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const bg = useColorModeValue('white', 'gray.800');
  const bg2 = useColorModeValue('gray.50', 'gray.700');
  const bg3 = useColorModeValue('gray.100', 'gray.600');

  const fetchOrders = async () => {
    try {
      const response = await API1.post('/orders/get', { params: { limit: 5 } });
      setOrders(response.data.data.docs || []);
    } catch (error) {
      utils.sendMessageToBugNinja("Error fetching orders:", error.message, error);
      setError('Failed to fetch orders.');
    }
  };

  const fetchOrderMonthlyStats = async () => {
    try {
      const response = await API1.post('/orders/get');
      const statsByMonth = new Array(12).fill(0);
      response.data.data.docs.forEach(order => {
        const month = new Date(order.date).getMonth();
        statsByMonth[month] += 1;
      });
      setOrderStats(statsByMonth);
    } catch (error) {
      utils.sendMessageToBugNinja("Error fetching monthly order stats:", error.message, error);
      setError('Failed to fetch monthly order statistics.');
    }
  };

  useEffect(() => {
    fetchOrders();
    fetchOrderMonthlyStats();
  }, []);

  const statusColor = (status) => {
    switch (status) {
      case 'Pending':
        return 'yellow';
      case 'Processing':
        return 'blue';
      case 'Completed':
        return 'green';
      case 'Cancelled':
        return 'red';
      default:
        return 'gray';
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + '...';
  };

  const handleView = (orderId) => {
    navigate(`/order-form/${orderId}`);
  };

  const handleEdit = (orderId) => {
    navigate(`/order-form/edit/${orderId}`);
  };

  const handleDelete = async (orderId) => {
    try {
      await API1.delete(`/orders/delete/${orderId}`);
      fetchOrders(); // Refresh the orders after deletion
    } catch (error) {
      utils.sendMessageToBugNinja("Error deleting order:", error.message, error);
      setError('Failed to delete order.');
    }
  };

  // Chart data and options for Order Statistics
  const orderStatisticsOptions = {
    chart: { id: 'order-statistics' },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    },
    colors: ['#59E81C'],
  };

  const orderStatisticsSeries = [
    {
      name: 'Orders',
      data: orderStats,
    },
  ];

  return (
      <Flex
          w="full"
          bg={bg2}
          py={10}
          alignItems="center"
          justifyContent="center"
      >
        <Stack direction={{ base: 'column', md: 'column' }} w="full" spacing={5}>
          {/* Order Table */}
          {/*<Box
              bg={bg}
              p={5}
              shadow="md"
              borderWidth="1px"
              borderRadius="md"
              w="full"
          >
            <chakra.h2 fontSize="xl" mb={5} color="black">
              Recent Orders
            </chakra.h2>
            <Stack
                direction={{ base: 'column' }}
                w="full"
                shadow="lg"
            >
              {orders.map((order, index) => (
                  <Flex
                      direction="column"
                      bg={bg2}
                      key={index}
                      p={3}
                      borderRadius="md"
                      mb={2}
                  >
                    <SimpleGrid
                        columns={{ base: 1, md: 4 }}
                        spacingY={3}
                        bg={bg3}
                        color="gray.500"
                        py={2}
                        px={4}
                        fontSize="sm"
                        fontWeight="bold"
                        borderRadius="md"
                    >
                      <span>Order ID</span>
                      <span>Date</span>
                      <span>Status</span>
                      <span>Actions</span>
                    </SimpleGrid>
                    <SimpleGrid
                        columns={{ base: 1, md: 4 }}
                        spacingY={3}
                        w="full"
                        py={2}
                        px={4}
                        fontSize="md"
                        fontWeight="normal"
                    >
                      <span>{truncateText(order._id, 15)}</span>
                      <chakra.span
                          textOverflow="ellipsis"
                          overflow="hidden"
                          whiteSpace="nowrap"
                      >
                        {new Date(order.date).toLocaleDateString()}
                      </chakra.span>
                      <Badge colorScheme={statusColor(order.status)}>{order.status}</Badge>
                      <Flex justify="flex-end">
                        <Tooltip label="View" aria-label="A tooltip for view">
                          <IconButton
                              color="white"
                              bg="#59E81C"
                              _hover={{ bg: '#59E81C' }}
                              _active={{ bg: '#59E81C' }}
                              icon={<AiOutlineEye />}
                              onClick={() => handleView(order._id)}
                              aria-label="View"
                              mr={2}
                          />
                        </Tooltip>
                        <Tooltip label="Edit" aria-label="A tooltip for edit">
                          <IconButton
                              colorScheme="green"
                              icon={<AiFillEdit />}
                              aria-label="Edit"
                              onClick={() => handleEdit(order._id)}
                              mr={2}
                          />
                        </Tooltip>
                        <Tooltip label="Delete" aria-label="A tooltip for delete">
                          <IconButton
                              colorScheme="red"
                              variant="outline"
                              icon={<BsFillTrashFill />}
                              onClick={() => handleDelete(order._id)}
                              aria-label="Delete"
                          />
                        </Tooltip>
                      </Flex>
                    </SimpleGrid>
                  </Flex>
              ))}
            </Stack>
          </Box>*/}

          {/* Order Statistics Chart */}
          <Box
              bg={bg}
              p={5}
              shadow="md"
              borderWidth="1px"
              borderRadius="md"
              w="full"
          >
            <chakra.h2 fontSize="xl" mb={5} color="black">
              Monthly Order Statistics
            </chakra.h2>
            <Chart
                options={orderStatisticsOptions}
                series={orderStatisticsSeries}
                type="bar"
                height={200}
            />
          </Box>
        </Stack>
      </Flex>
  );
};

export default AnalyticsDashboard;
