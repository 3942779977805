import {
    FaChartLine,
    FaUserShield,
    FaUser,
    FaReceipt,
    FaBell,
    FaCommentDots,
    FaQuestionCircle,
} from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { AdminPermissions } from "../../pages/PrimaryFeatures/AdminUserManagement/AdminPermissions";

const FeatureSections = [
    {
        key: 'dashboard',
        title: 'Dashboard',
        icon: FaChartLine,
        permissionsRequired: [AdminPermissions.DASHBOARD],
        links: [
            {
                title: 'Overview',
                description: 'Monitor key metrics to make data-driven decisions.',
                icon: FaChartLine,
                link: '/dashboard',
                permissionsRequired: [AdminPermissions.DASHBOARD],
            },
        ],
    },
    {
        key: 'adminManagement',
        title: 'Admin Management',
        icon: IoIosArrowDown,
        permissionsRequired: [AdminPermissions.ADMIN],
        links: [
            {
                title: 'User & Access Control',
                description: 'Manage admin user roles, profiles, permissions, and contact details.',
                icon: FaUserShield,
                link: '/admin-users',
                permissionsRequired: [AdminPermissions.ADMIN],
            },
        ],
    },
    {
        key: 'customersAndOrders',
        title: 'Customers & Orders',
        icon: IoIosArrowDown,
        permissionsRequired: [AdminPermissions.CUSTOMER, AdminPermissions.ORDER],
        links: [
            {
                title: 'Customers',
                description: 'Maintain a database of your customers.',
                icon: FaUser,
                link: '/customers',
                permissionsRequired: [AdminPermissions.CUSTOMER],
            },
            {
                title: 'Order Form Submission',
                description: 'Handle and track order forms.',
                icon: FaReceipt,
                link: '/order-form',
                permissionsRequired: [AdminPermissions.ORDER],
            },
        ],
    },
    {
        key: 'helpAndSupport',
        title: 'Help & Support',
        icon: IoIosArrowDown,
        permissionsRequired: [
            AdminPermissions.NOTIFICATION,
            AdminPermissions.FEEDBACK,
            AdminPermissions.FAQ,
        ],
        links: [
            {
                title: 'Notifications',
                description: 'Manage notifications for users.',
                icon: FaBell,
                link: '/notifications',
                permissionsRequired: [AdminPermissions.NOTIFICATION],
            },
            {
                title: 'Feedback',
                description: 'Manage user feedback.',
                icon: FaCommentDots,
                link: '/feedback',
                permissionsRequired: [AdminPermissions.FEEDBACK],
            },
            {
                title: 'FAQs',
                description: 'Update FAQ entries to maintain accuracy and relevance.',
                icon: FaQuestionCircle,
                link: '/faqs',
                permissionsRequired: [AdminPermissions.FAQ],
            },
        ],
    },
];

export default FeatureSections;
