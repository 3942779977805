import React, { useEffect, useState, useCallback } from 'react';
import MessageTable from './MessageTable';
import MessageTableTopBar from './MessageTableTopBar';
import { fetchAllMessages } from '../MessageAPI';

const MessageContainer = () => {
    const [messages, setMessages] = useState([]);
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [filterOptions, setFilterOptions] = useState({});
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const refetchMessageData = useCallback(async (options={}) => {
        setDataLoaded(false);
        try {
            console.log('Fetching all messages with options:', filterOptions);
            const data = await fetchAllMessages(pageNumber, 10, filterOptions);
            setMessages(data.messages);
            setTotalPages(data.totalPages);
            setError('');
            setDataLoaded(true);
        } catch (error) {
            console.error('Error fetching all messages:', error.message);
            setError('Failed to fetch message data.');
            setMessages([]);
            setTotalPages(0);
        }
    }, [filterOptions, pageNumber]);

    useEffect(() => {
        refetchMessageData();
    // }, [pageNumber, refreshFlag, refetchMessageData, totalPages, fetchTrigger, filterOptions]);
    }, []);

    return (
        <>
            <MessageTableTopBar
                refetchMessageData={refetchMessageData}
                setPageNumber={setPageNumber}
                setFilterOptions={setFilterOptions}
                setFetchTrigger={setFetchTrigger}
            />
            <MessageTable
                messages={messages}
                setMessages={setMessages}
                setRefreshFlag={setRefreshFlag}
                refetchMessageData={refetchMessageData}
                error={error}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                filterOptions={filterOptions}
                dataLoaded={dataLoaded}
            />
        </>
    );
};

export default MessageContainer;
