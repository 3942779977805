import React, { useEffect, useRef, useState } from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    Box,
    Text,
    SimpleGrid, Spinner
} from '@chakra-ui/react';
import { ThemedStyledInput } from "../../../../components/Styled";
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../components/Styled/StyledButtons";
import { useDrawer } from '../../../../Context/DrawerContext/DrawerContext';
import { fetchCustomerById, updateCustomer } from "../CustomerAPI";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const CustomerEditForm = ({ customerId, refetchCustomerData, setRefreshFlag }) => {
    const { closeDrawer } = useDrawer();
    const showToast = useSingleToast();
    const hasFetched = useRef(false);
    const [initialValues, setInitialValues] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: ''
    });
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    useEffect(() => {
        const fetchAndSetCustomerData = async () => {
            if (hasFetched.current) {
                return;
            }

            try {
                const customerData = await fetchCustomerById(customerId);
                if (customerData) {
                    setInitialValues({
                        firstName: customerData.firstName,
                        lastName: customerData.lastName,
                        phone: customerData.phone,
                        email: customerData.email,
                    });
                }
                hasFetched.current = true;
            } catch (error) {
                showToast({
                    title: 'Error fetching customer data',
                    description: error.message || 'An unexpected error occurred.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        fetchAndSetCustomerData();
    }, [customerId, showToast]);


    const customerSchema = Yup.object().shape({
        firstName: Yup.string().required('First name is required.'),
        lastName: Yup.string().required('Last name is required.'),
        phone: Yup.string()
            .matches(/^[0-9]+$/, "Phone number is not valid")
            .min(7, 'Phone number must be at least 7 digits')
            .max(15, 'Phone number must not exceed 15 digits')
            .required('Phone number is required.'),
        email: Yup.string().email('Invalid email address').required('Email is required.')
    });

    const handleSubmit = async (values, actions) => {
        try {
            await updateCustomer(customerId, values);
            showToast({
                title: 'Customer updated successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            refetchCustomerData();
            setRefreshFlag(prevFlag => !prevFlag); // Optionally trigger refresh
            closeDrawer();
        } catch (error) {
            showToast({
                title: 'Error updating customer',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            actions.setSubmitting(false);
        }
    };

    const requestConfirm = (options) => {
        setConfirmDialog({
            isOpen: true,
            title: options.title,
            message: options.message,
            onConfirm: options.onConfirm,
        });
    };

    const handleCancel = () => {
        requestConfirm({
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: closeDrawer
        });
    };

    const handleConfirm = () => {
        confirmDialog.onConfirm();
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    const handleCancelDialog = () => {
        setConfirmDialog((prevState) => ({ ...prevState, isOpen: false }));
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={customerSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {(formikProps) => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={{base: 0, md: 8}}>
                            <FormActionLabel formAction="edit" formName="Customer"/>
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <FieldControl formikProps={formikProps} name="firstName" label="First Name" placeholder="Enter First Name" />
                                <FieldControl formikProps={formikProps} name="lastName" label="Last Name" placeholder="Enter Last Name" />
                                <FieldControl formikProps={formikProps} name="phone" label="Phone" placeholder="Enter Phone Number" />
                                <FieldControl formikProps={formikProps} name="email" label="Email" placeholder="Enter Email Address" isReadOnly />
                            </SimpleGrid>
                            <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }}>
                                <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
                                <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                                    <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                                        Cancel
                                    </CustomButton>
                                    <CustomButton
                                        type="submit"
                                        disabled={formikProps.isSubmitting}
                                        style={{
                                            opacity: formikProps.isSubmitting ? 0.7 : 1,
                                            pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                                        }}
                                    >
                                        {formikProps.isSubmitting ? (
                                            <>
                                                <Spinner size="xs" mr={2} />
                                                Updating...
                                            </>
                                        ) : 'Update'}
                                    </CustomButton>
                                </ButtonStack>
                            </Box>
                        </VStack>
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={handleCancelDialog}
                onConfirm={handleConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

const FieldControl = ({ formikProps, name, label, placeholder, isReadOnly }) => (
    <GridItem colSpan={[3, 1]}>
        <FormControl isInvalid={formikProps.errors[name] && formikProps.touched[name]}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <ThemedStyledInput
                {...formikProps.getFieldProps(name)}
                id={name}
                placeholder={placeholder}
                isReadOnly={isReadOnly}
            />
            {formikProps.errors[name] && formikProps.touched[name] && (
                <Text color="red.500" fontSize="sm">{formikProps.errors[name]}</Text>
            )}
        </FormControl>
    </GridItem>
);

export default CustomerEditForm;
