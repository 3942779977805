import React from 'react';
import { Box, Grid, Text, VStack, Image, Link, Divider } from '@chakra-ui/react';
import EMARKETLOGO from "../../assests/images/emarket-logo.png";
import { Icon } from "@iconify/react";

const statusIcons = {
    Approved: { icon: 'mdi:check-circle-outline', color: '#48BB78' },
    Declined: { icon: 'mdi:close-circle-outline', color: '#E53E3E' },
    Complete: { icon: 'mdi:check-all', color: '#3182CE' },
    Review: { icon: 'mdi:file-document-edit-outline', color: '#D69E2E' },
    Submitted: { icon: 'mdi:clipboard-text-outline', color: '#CBD5E0' }
};

const PrintableComponent = React.forwardRef(({ data, title }, ref) => {
    if (!data) {
        return <div>No data available</div>;
    }

    const { Documents, ...otherDetails } = data;

    return (
        <div ref={ref}>
            <VStack spacing={4} p={5} align="stretch">
                <Image src={EMARKETLOGO} alt="WUC Logo" htmlWidth="100px" alignSelf="center" mb={2} />
                <Text fontSize="2xl" fontWeight="bold" alignSelf="center" mb={4}>{title}</Text>

                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                    {Object.entries(otherDetails).map(([sectionTitle, sectionData]) => (
                        <Box key={sectionTitle} mb={4}>
                            <Text fontSize="xl" fontWeight="bold" mb={2}>{sectionTitle}</Text>
                            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                {Object.entries(sectionData).map(([key, value]) => (
                                    <Box key={key} p={2} border="1px solid #e8e8e8">
                                        <Text fontWeight="semibold">{key}:</Text>
                                        <Text display="flex" alignItems="center">
                                            {value !== undefined ? value : 'Not Available'}
                                            {key === 'Verified' && (
                                                <Icon icon={value === 'Verified' ? 'mdi:check-circle-outline' : 'mdi:close-circle-outline'}
                                                      style={{ marginLeft: '8px', color: value === 'Verified' ? '#48BB78' : '#E53E3E' }}
                                                />
                                            )}
                                            {key in statusIcons && (
                                                <Icon
                                                    icon={statusIcons[key].icon}
                                                    style={{ marginLeft: '8px', color: statusIcons[key].color }}
                                                />
                                            )}
                                        </Text>
                                    </Box>
                                ))}
                            </Grid>
                        </Box>
                    ))}
                </Grid>
            </VStack>
        </div>
    );
});

export default PrintableComponent;
