import React from "react";
import AuthenticationContainer from "../../components/Authentication/AuthenticationContainer";
import ResetPasswordForm from "./components/ResetPasswordForm";

const ResetPassword = () => {
  return (
    <>
      <AuthenticationContainer Children={<ResetPasswordForm />} />
    </>
  );
};

export default ResetPassword;
