import React from 'react';
import { Formik, Form, Field } from 'formik';
import { FormControl, Stack } from '@chakra-ui/react';
import * as Yup from 'yup';
import { CustomButton } from "../../../../components/Styled/StyledButtons";
import { Icon } from "@iconify/react";
import {ThemedStyledInput} from "../../../../components/Styled";
import FieldErrorMessage from "../../../../components/FieldErrorMessage/FieldErrorMessage";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Search & Filters Error: Invalid email address, please input a valid email address')
        .max(50, 'Search & Filters Error: Email Must be 50 characters or less')
        .trim('Search & Filters Error: Email cannot have whitespace')
        .strict(true),
    firstName: Yup.string()
        .max(30, 'Search & Filters Error: First Name Must be 30 characters or less')
        .trim('Search & Filters Error: First Name cannot have whitespace')
        .strict(true),
    lastName: Yup.string()
        .max(30, 'Search & Filters Error: Last Name Must be 30 characters or less')
        .trim('Search & Filters Error: Last Name cannot have whitespace')
        .strict(true),
    phone: Yup.string()
        .matches(/^[0-9]+$/, 'Search & Filters Error: Phone number must be numeric')
        .min(7, 'Search & Filters Error: Phone number must be at least 7 digits')
        .max(15, 'Search & Filters Error: Phone number must not exceed 15 digits')
});


const AdminSearchFilter = ({ onFilterChange }) => {
    const showToast = useSingleToast();

    return (
        <Formik
            initialValues={{ firstName: '', lastName: '', email: '', phone: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                if (!values.email && !values.firstName && !values.lastName && !values.phone) {
                    onFilterChange({ firstName: '', lastName: '', email: '', phone: '' });
                } else {
                    onFilterChange(values);
                }
                setSubmitting(false);
            }}
            onReset={() => {
                onFilterChange({ firstName: '', lastName: '', email: '', phone: '' });
            }}
        >
            {({ handleSubmit, resetForm }) => (
                <Form>
                    <Stack spacing={2} direction="row" align="center">
                        <FormControl>
                            <Field as={ThemedStyledInput} id="firstName" name="firstName" type="text" placeholder="Search by First Name" />
                        </FormControl>
                        <FormControl>
                            <Field as={ThemedStyledInput} id="lastName" name="lastName" type="text" placeholder="Search by Last Name" />
                        </FormControl>
                        <FormControl>
                            <Field as={ThemedStyledInput} id="email" name="email" type="email" placeholder="Search by Email" />
                        </FormControl>
                        <FormControl>
                            <Field as={ThemedStyledInput} id="phone" name="phone" type="phone" placeholder="Search by Phone" />
                        </FormControl>
                        <CustomButton onClick={handleSubmit} type="search" tooltipLabel="Search" icon={<Icon icon="bi:search" />} showLabel={false}>
                            Search
                        </CustomButton>
                        <CustomButton
                            type="reset"
                            onClick={() => {
                                resetForm();
                                onFilterChange({ firstName: '', lastName: '', email: '', phone: '' });
                            }}
                            tooltipLabel="Clear Filters"
                            icon={<Icon icon="radix-icons:reset" />}
                            showLabel={false}
                        >
                            Clear
                        </CustomButton>
                    </Stack>
                    <FieldErrorMessage name="firstName" />
                    <FieldErrorMessage name="lastName" />
                    <FieldErrorMessage name="email" />
                    <FieldErrorMessage name="phone" />
                </Form>
            )}
        </Formik>
    );
};

export default AdminSearchFilter;
