import { API1 } from "../../../utils/api";

// Add Admin
export const addAdmin = async (adminData) => {
    try {
        const dataWithRoleAndCreator = {
            ...adminData,
            role: 'admin',
            createdBy: 'admin',
            permissions: adminData.permissions?.filter(p => p != null) ?? [],
        };
        const response = await API1.post('/auth/signup', dataWithRoleAndCreator);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchAllAdmins = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        filterOptions.role='admin';
        const data = {
            filterOptions,
            pageNumber,
            pageSize
        };

        const response = await API1.post('/auth/users',data);

        if (response.status === 200) {
            const { docs: admins, totalDocs } = response.data.data;
            return {
                admins,
                totalPages: Math.ceil(totalDocs / pageSize),
            };
        } else {
            throw new Error('Unexpected response from the server while fetching admins.');
        }
    } catch (error) {
        console.error("Error fetching admins:", error);
        throw new Error('Failed to fetch admins. Please try again.');
    }
};


// Fetch Admin by ID
export const fetchAdminById = async (adminId) => {
    try {
        const data = { 
            filterOptions: { id: adminId, role: 'admin' }, 
            pageNumber: 1, 
            pageSize: 1 
        };

        const response = await API1.post(`/auth/users`, data);
        if (response.data.status === 'Success' && response.data.data.docs.length > 0) {
            const adminData = response.data.data.docs[0];
            adminData.permissions = adminData.permissions?.filter(p => p != null) ?? [];
            return adminData;
        } else {
            throw new Error('Admin not found.');
        }
    } catch (error) {
        throw error;
    }
};


// Fetch Admin by ID
export const fetchAdminByEmail = async (userEmail) => {
    try {
        const data = {
            filterOptions: { email: userEmail, role: 'admin' },
            pageNumber: 1,
            pageSize: 1
        };
        
        const response = await API1.post(`/auth/users`, data);
        if (response.data.status === 'Success' && response.data.data.docs.length > 0) {
            const adminData = response.data.data.docs[0];
            adminData.permissions = adminData.permissions?.filter(p => p != null) ?? [];
            return adminData;
        } else {
            throw new Error('Admin not found.');
        }
    } catch (error) {
        throw error;
    }
};

// Update Admin
export const updateAdmin = async (adminId, adminData) => {
    try {
        if (adminData.permissions) {
            adminData.permissions = adminData.permissions.filter(p => p != null);
        }
        const response = await API1.put('/auth/user/update', { _id: adminId, ...adminData });
        return response.data;
    } catch (error) {
        throw error;
    }
};
