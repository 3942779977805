import React from 'react';
import { Box, Button} from '@chakra-ui/react';

const PaginationControls = ({ pageNumber, setPageNumber, totalPages }) => {

    const maxPageVisible = 10;
    let startPage = Math.max(1, pageNumber - Math.floor(maxPageVisible / 2));
    let endPage = startPage + maxPageVisible - 1;

    if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(1, endPage - maxPageVisible + 1);
    }

    const handlePageClick = (page) => {
        setPageNumber(page);
    };

    return (
        <Box display="flex" justifyContent="flex-end" alignItems="center" mt="-15px">
            {startPage > 1 && (
                <Button size="sm" variant="ghost" onClick={() => setPageNumber(startPage - 1)}>
                    {'<'}
                </Button>
            )}
            {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(page => (
                <Button
                    key={page}
                    size="sm"
                    variant="ghost"
                    colorScheme={pageNumber === page ? 'blue' : 'gray'}
                    onClick={() => handlePageClick(page)}
                >
                    {page}
                </Button>
            ))}
            {endPage < totalPages && (
                <Button size="sm" variant="ghost" onClick={() => setPageNumber(endPage + 1)}>
                    {'>'}
                </Button>
            )}
        </Box>
    );
};

export default PaginationControls;
