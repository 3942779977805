const AdminPermissions = {
    DASHBOARD: 'Dashboard',
    CUSTOMER: 'Customer Management',
    ADMIN: 'Admin Management',
    ORDER: 'Order Form Management',
    ADDRESS: 'Address Management',
    NOTIFICATION: 'Notification Management',
    FEEDBACK: 'Feedback Management',
    FAQ: 'FAQ',
};

module.exports = {
    AdminPermissions
};
