import React from 'react';
import ReactDOM from "react-dom/client";
import {BrowserRouter as Router} from 'react-router-dom';
import {ChakraProvider} from '@chakra-ui/react';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {ThemeProvider} from 'styled-components';
import customTheme from './theme/customTheme';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
            <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_RECAPTCHA_LOCAL_SITE_KEY}
            >
                <Router>
                    <ChakraProvider theme={customTheme}>
                        <ThemeProvider theme={customTheme}>
                                <App/>
                        </ThemeProvider>
                    </ChakraProvider>
                </Router>
            </GoogleReCaptchaProvider>
    </React.StrictMode>
);

reportWebVitals();
