import React from 'react';
import {
    VStack, Box, Text, Stack, SimpleGrid, Badge
} from '@chakra-ui/react';
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import SectionHeading from "../../../../components/SectionHeading/SectionHeading";
import utils from "../../../../utils/commonFunctions";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import {ButtonStack, CustomButton} from "../../../../components/Styled/StyledButtons";
import {useDrawer} from "../../../../Context/DrawerContext/DrawerContext";


const FaqViewForm = ({ formData }) => {
    const { closeDrawer } = useDrawer();
    const { question, answer, category, published } = formData;

    const getStatusColor = (status) => {
        return status ? 'green' : 'red';
    };

    return (
        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
            <FormActionLabel formAction="view" formName="FAQ"/>
            <Stack spacing={4} px={2} py={4}>
                <SectionHeading icon="fluent:apps-list-detail-24-filled" text="FAQ Information" />
                <SimpleGrid columns={{base: 1, sm: 1}} gap={4} px={4}>
                    <Box>
                        <Text>
                            <strong>Question: </strong>{question}
                        </Text>
                    </Box>
                    <Box>
                        <Text>
                            <strong>Answer: </strong>
                            <div dangerouslySetInnerHTML={{ __html: answer }} />
                        </Text>
                    </Box>
                    <Box>
                        <Text>
                            <strong>Category: </strong>
                            <Badge variant="outline" colorScheme="orange">{category}</Badge>
                        </Text>
                    </Box>
                    <Box>
                        <Text>
                            <strong>Status: </strong>
                            <Badge variant="solid" colorScheme={getStatusColor(published)}>
                                {published ? "Published" : "Unpublished"}
                            </Badge>
                        </Text>
                    </Box>
                    <Text><strong>Created At:</strong> {`${utils.DateFormat(formData.createdAt)}`}</Text>
                    <Text><strong>Updated At:</strong> {`${utils.DateFormat(formData.updatedAt)}`}</Text>

                    <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }} mt={8} mb={8}>
                        <CustomAlert status="warning" message="View Only Mode" />
                        <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                            <CustomButton onClick={closeDrawer} type="cancel" showIcon={false}>
                                Close
                            </CustomButton>
                        </ButtonStack>
                    </Box>
                </SimpleGrid>
            </Stack>
        </VStack>
    );
};

export default FaqViewForm;