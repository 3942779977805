import React, {useCallback} from 'react';
import {Flex, GridItem} from '@chakra-ui/react';
import { StyledGrid } from "../../../../components/Styled/StyledComponents";
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import MessageSearchFilter from "./MessageSearchFilter";


const MessageTableTopBar = ({ refetchMessageData,setPageNumber, setFilterOptions, setFetchTrigger }) => { // Pass refetchMessageData if needed for the AddForm to trigger a refresh
    const { openDrawer } = useDrawer();

    const handleFilterChange = useCallback(async (newFilterOptions) => {
        setPageNumber(1);
        await new Promise(resolve => setTimeout(resolve, 0));
        setFilterOptions(newFilterOptions);
        setFetchTrigger(f => !f);
    }, [setPageNumber, setFilterOptions, setFetchTrigger]);

    return (
        <>
            <StyledGrid templateColumns="repeat(12, 1fr)" alignItems="center">
                <GridItem colSpan={{ base: 10, md: 11 }}>
                    <MessageSearchFilter onFilterChange={handleFilterChange} />
                </GridItem>
            </StyledGrid>
            <Flex bg="white" mb={4}></Flex>
        </>
    );
};

export default MessageTableTopBar;
