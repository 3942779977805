import React, { useRef } from 'react';
import {
    VStack, Box, Text, Badge, SimpleGrid, Stack, IconButton, Tooltip,
} from '@chakra-ui/react';
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../components/Styled/StyledButtons";
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import { Icon } from "@iconify/react";
import { useReactToPrint } from 'react-to-print';
import PrintableComponent from "../../../../components/PrintableComponent/PrintableComponent";
import DetailSection from "../../../../components/DetailSection/DetailSection";

const MessageViewForm = ({ formData }) => {
    const printComponentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current,
        onBeforeGetContent: () => console.log("Preparing document..."),
        onAfterPrint: () => console.log("Printing process done."),
    });

    const handlePrintDebug = () => {
        console.log("Attempting to print...");
        handlePrint();
    };

    const { closeDrawer } = useDrawer();
    const { fullName, phoneNumber, email, subject, comments, status, createdAt, updatedAt } = formData;

    // Data for printing
    const printData = {
        'Message Information': {
            'Full Name': fullName || '',
            'Phone Number': phoneNumber || '',
            'Email': email || '',
            'Subject': subject || '',
            'Comments': comments || '',
            'Status': status || 'Pending',
            'Created At': new Date(createdAt).toLocaleString(),
            'Updated At': new Date(updatedAt).toLocaleString(),
        },
    };

    return (
        <>
            <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                <FormActionLabel formAction="view" formName="Message's Details" />
                <Stack spacing={4} px={2} py={4} mt={4}>
                    <DetailSection title="Message Information" icon="mdi:message-outline">
                        <SimpleGrid columns={{ base: 1, sm: 1 }} gap={4} px={4}>
                            <Text><strong>Full Name:</strong> {fullName}</Text>
                            <Text><strong>Email:</strong> {email}</Text>
                            <Text><strong>Phone:</strong> {phoneNumber}</Text>
                            <Text><strong>Subject:</strong> {subject}</Text>
                            <Text><strong>Comments:</strong> {comments}</Text>
                            {/*<Box>
                                <Text>
                                    <strong>Status:</strong>
                                    <Badge colorScheme={
                                        status === 'Resolved' ? 'green' :
                                            status === 'Reviewed' ? 'yellow' : 'red'
                                    } ml={2} variant="solid">
                                        {status}
                                    </Badge>
                                </Text>
                            </Box>*/}
                        </SimpleGrid>
                    </DetailSection>

                    <DetailSection title="Timestamps" icon="mdi:clock-outline">
                        <SimpleGrid columns={{ base: 1, sm: 1 }} gap={4} px={4}>
                            <Text><strong>Created At:</strong> {new Date(createdAt).toLocaleString()}</Text>
                            {/*<Text><strong>Updated At:</strong> {new Date(updatedAt).toLocaleString()}</Text>*/}
                        </SimpleGrid>
                    </DetailSection>

                    <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }} mt={8} mb={8}>
                        <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                            <CustomButton type="cancel" onClick={closeDrawer} showIcon={false}>
                                Close
                            </CustomButton>
                           {/* <Tooltip label="Print Message Details">
                                <IconButton
                                    aria-label="Print"
                                    icon={<Icon icon="material-symbols-light:print-outline" />}
                                    onClick={handlePrintDebug}
                                />
                            </Tooltip>*/}
                        </ButtonStack>
                    </Box>
                </Stack>
            </VStack>
            <div style={{ display: "none" }}>
                <PrintableComponent ref={printComponentRef} data={printData} title="Message's Details" />
            </div>
        </>
    );
};

export default MessageViewForm;
