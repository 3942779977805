import React, { useEffect, useState, useCallback } from 'react';
import {Box} from "@chakra-ui/react";
import NotificationTableTopBar from "./NotificationTableTopBar";
import NotificationTable from "./NotificationTable";
import {fetchAllNotifications} from "../NotificationAPI";

const NotificationContainer = () => {
    const [notifications, setNotifications] = useState([]);
    const [filterOptions, setFilterOptions] = useState({});
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const refetchNotificationData = useCallback(async (options = {}) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllNotifications(pageNumber, 5, filterOptions);
            setNotifications(data.notifications);
            setTotalPages(data.totalPages);
            setError('');
            setDataLoaded(true);
        } catch (error) {
            setError(error.message);
            setNotifications([]);
            setTotalPages(0);
        }
    }, [pageNumber, filterOptions, setTotalPages]);


    useEffect(() => {
        refetchNotificationData();
    }, [fetchTrigger, refetchNotificationData, refreshFlag, totalPages, filterOptions]);

    if (!notifications) {
        return (
            <Box textAlign="center" my="4" p="4" color="red.600" bg="gray.100">
                <p>Service is down...</p>
            </Box>
        );
    }

    return (
        <>
            <NotificationTableTopBar
                refetchNotificationData={refetchNotificationData}
                setPageNumber={setPageNumber}
                setFilterOptions={setFilterOptions}
                setFetchTrigger={setFetchTrigger}
            />
            <NotificationTable
                notifications={notifications}
                setNotifications={setNotifications}
                error={error}
                pageNumber={pageNumber}
                setRefreshFlag={setRefreshFlag}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                filterOptions={filterOptions}
                dataLoaded={dataLoaded}
                refetchNotificationData={refetchNotificationData}
            />
        </>
    );
};

export default NotificationContainer;
