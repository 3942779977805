import styled from '@emotion/styled';
import {
    Button,
    Grid,
    InputGroup,
    Tooltip as ChakraTooltip,
    IconButton as ChakraIconButton,
    TableContainer as ChakraTableContainer,
    Box,
    theme,
} from '@chakra-ui/react';

// Refactor with destructuring for StyledGrid
export const StyledGrid = styled(Grid)`
  ${({ theme: { space, colors, shadows, radii, breakpoints } }) => `
    display: grid;
    grid-gap: ${space[6]};
    background-color: ${colors.white};
    box-shadow: ${shadows.md};
    padding: ${space[4]};
    border-top-left-radius: ${radii.xl};
    border-top-right-radius: ${radii.xl};
    @media (min-width: ${breakpoints.md}) {
      padding: ${space[8]};
    }
  `}
`;

// Refactor with destructuring for StyledInputGroup
export const StyledInputGroup = styled(InputGroup)`
  ${({ theme: { breakpoints } }) => `
    width: 100%;
    @media (min-width: ${breakpoints['md']}) {
      width: 50%;
    }
  `}
`;

// FullWidthButton does not need theme destructuring for simple styles
export const FullWidthButton = styled(Button)`
  width: 100%;
`;

// Refactor with destructuring for Tooltip
export const Tooltip = styled(ChakraTooltip)`
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes.xs};
  `}
`;

// IconButton can be customized further if needed, example not provided as no styles were initially defined
export const IconButton = styled(ChakraIconButton)`
  // Custom styling if needed
`;

// Refactor with destructuring for TableContainer
export const TableContainer = styled(ChakraTableContainer)`
  ${({ theme: { colors, shadows, radii, space } }) => `
    background-color: ${colors.white};
    box-shadow: ${shadows.md};
    border-bottom-left-radius: ${radii.xl};
    border-bottom-right-radius: ${radii.xl};
    padding: ${space[5]};
    margin-top: -15px;
    margin-bottom: 100px;
    overflow-y: auto;
    min-height: 100px;
    max-height: 650px;
  `}
`;

export const StyledBox = styled(Box)`
  ${({ theme: { colors, space, breakpoints } }) => `
    background: ${colors.gray[50]};
    padding-top: 5rem;
    padding-left: ${space[4]};
    padding-right: ${space[4]};

    @media (min-width: ${breakpoints.md}) {
      padding-left: ${space[16]};
      padding-right: ${space[16]};
    }
  `}
`;

export const ContentBox = styled(Box)`
  ${({ theme: { colors, space, breakpoints } }) => `
    margin: ${space[4]};
    padding-left: ${space[2]}; // Start with smaller padding for smaller screens
    padding-right: ${space[2]}; // Start with smaller padding for smaller screens
    padding-top: ${space[2]};
    padding-bottom: ${space[2]};
    color: ${colors.blue[800]};

    @media (min-width: ${breakpoints.md}) {
      padding-left: ${space[4]};
      padding-right: ${space[4]};
    }
  `}
`;

// Direct exports if no additional styles are defined
export const StyledTableContainer = TableContainer;
export const StyledIconButton = IconButton;
export const StyledTooltip = Tooltip;
