import React, { useCallback } from 'react';
import { Flex, GridItem } from '@chakra-ui/react';
import {useDrawer} from "../../../../Context/DrawerContext/DrawerContext";
import {StyledGrid} from "../../../../components/Styled/StyledComponents";
import OrderSearchFilter from "./OrderSearchFilter";
import {ButtonStack, CustomButton} from "../../../../components/Styled/StyledButtons";
import Icon from "../../../../@core/components/icon";
import OrderAddForm from "./OrderAddForm";

const OrderTableTopBar = ({ refetchOrderData, setPageNumber, setFilterOptions, setFetchTrigger }) => {
    const { openDrawer } = useDrawer();

    const handleFilterChange = useCallback(async (newFilterOptions) => {
        setPageNumber(1);
        await new Promise(resolve => setTimeout(resolve, 0));
        setFilterOptions(newFilterOptions);
        setFetchTrigger(f => !f);
    }, [setPageNumber, setFilterOptions, setFetchTrigger]);



    return (
        <>
            <StyledGrid templateColumns="repeat(12, 1fr)"  alignItems="center">
                <GridItem colSpan={{ base: 12, md: 12 , lg:12}}>
                    <OrderSearchFilter onFilterChange={handleFilterChange} />
                </GridItem>
                {/*<GridItem colSpan={{ base: 12, md: 1 }}>
                    <ButtonStack direction="row">
                        <CustomButton
                            type="addNew"
                            showIcon={true}
                            showTooltip={true}
                            tooltipLabel="Add New Order"
                            icon={<Icon icon="majesticons:folder-plus-line" />}
                            onClick={() => openDrawer({
                                title: 'Add New Order',
                                component: OrderAddForm,
                                props: { refetchOrderData }
                            }, 'md')}
                        >
                            Add New
                        </CustomButton>
                    </ButtonStack>
                </GridItem>*/}
            </StyledGrid>
            <Flex bg="white" mb={4}></Flex>
        </>
    );
};

export default OrderTableTopBar;
