import React, {useCallback, useEffect, useState} from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Badge,
} from '@chakra-ui/react';
import CustomerEditForm from './CustomerEditForm';
import CustomerViewForm from './CustomerViewForm';
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import {EditButton, ViewButton} from "../../../../components/Styled/ActionButtons";
import { StyledTableContainer } from "../../../../components/Styled/StyledComponents";
import PaginationControls from "../../../../Context/PaginationControls/PaginationControls";
import {fetchCustomerById, fetchAllCustomers, fetchUserProfileById} from "../CustomerAPI";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const CustomerTable = ({ customers, setCustomers, pageNumber, dataLoaded, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions }) => {
    const { openDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [error, setError] = useState('');



    const handleEdit = (customerId) => {
        openDrawer({
            title: 'Edit Customer',
            component: CustomerEditForm,
            props: {
                customerId: customerId,
                refetchCustomerData: refetchCustomerData,
                setRefreshFlag: setRefreshFlag,
            },
            key: `edit-${customerId}`,
        }, 'md');
    };



    const handleView = async (customer) => {

        try {
            const updatedCustomerData = await fetchCustomerById(customer._id);
            let profileResponse = { success: false, data: {} };
            try {
                const response = await fetchUserProfileById(customer._id);
                profileResponse = response.success && response.data.length > 0 ? { success: true, data: response.data[0] } : { success: false, data: {} };
            } catch (profileError) {
                console.error("Failed to fetch customer profile data:", profileError);
            }


            openDrawer({
                title: 'View Customer',
                component: CustomerViewForm,
                props: {
                    formData: updatedCustomerData,
                    profileData: profileResponse ? profileResponse.data : {},
                },
                key: `view-${customer._id}`,
            }, 'md');

        } catch (error) {
            console.error("Failed to fetch customer data:", error);
            setError("Failed to fetch customer data. Please try again later.");
        }
    };




    const refetchCustomerData = useCallback(async (options={}) => {
        setError('');
        try {
            const data = await fetchAllCustomers(pageNumber, 10, filterOptions);
            setCustomers(data.customers);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error("Fetch customers error:", error.message);
        }
    }, [filterOptions, pageNumber, setCustomers, setTotalPages]);

    useEffect(() => {
        refetchCustomerData();
    }, [refetchCustomerData, pageNumber, filterOptions]);

    if (error) {
        return <Box textAlign="center" my="4">Failed to fetch customer data: {error}</Box>;
    }

    if (dataLoaded && Array.isArray(customers) && customers.length === 0) {
        return <Box textAlign="center" my="4">No records found.</Box>;
    }

    return (
        <>
            <StyledTableContainer>
                <PaginationControls
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    totalPages={totalPages}
                />
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>First Name</Th>
                            <Th>Last Name</Th>
                            <Th>Email</Th>
                            <Th>Phone</Th>
                            <Th>Verification Status</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {customers.map((customer) => (
                            <Tr key={customer._id}>
                                <Td>{customer.firstName}</Td>
                                <Td>{customer.lastName}</Td>
                                <Td>{customer.email}</Td>
                                <Td>{customer.phone}</Td>
                                <Td>
                                    <Badge colorScheme={customer.isVerified ? 'green' : 'red'} ml={2} variant="solid">
                                        {customer.isVerified ? 'Verified' : 'Not Verified'}
                                    </Badge>
                                </Td>
                                <Td>
                                    <ViewButton onClick={() => handleView(customer)} />
                                    <EditButton onClick={() => handleEdit(customer._id)} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>
        </>
    );
};

export default CustomerTable;
