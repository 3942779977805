import React, { useState } from 'react';
import {
    Flex, FormControl, FormLabel, InputGroup, InputRightElement, Button,
    IconButton, Heading, Box, VStack, SimpleGrid, GridItem, Stack
} from '@chakra-ui/react';
import { RepeatIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import utils from "../../../utils/commonFunctions";
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";
import CustomAlert from "../../Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../Styled/StyledButtons";
import FormActionLabel from "../../Styled/FormActionLabel";
import { ThemedStyledInput } from "../../Styled";
import {useDrawer} from "../../../Context/DrawerContext/DrawerContext";
import {useNavigate} from "react-router-dom";

const AdminPasswordChange = ({ userId }) => {
    const showToast = useSingleToast();
    const { closeDrawer } = useDrawer();
    const navigate = useNavigate();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const generatePassword = () => {
        const passwordLength = 10;
        const chars = 'abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890';
        let newPassword = '';
        let criteria = { lower: 0, upper: 0, digit: 0, special: 0 };

        while (criteria.lower < 1 || criteria.upper < 1 || criteria.digit < 1 || criteria.special < 1 || newPassword.length < passwordLength) {
            let char = chars.charAt(Math.floor(Math.random() * chars.length));
            newPassword += char;
            if (/[a-z]/.test(char)) criteria.lower++;
            if (/[A-Z]/.test(char)) criteria.upper++;
            if (/[0-9]/.test(char)) criteria.digit++;
            if (/[^a-zA-Z0-9]/.test(char)) criteria.special++;
        }

        setNewPassword(newPassword);
        setConfirmPassword(newPassword);
    };

    const toggleShowOldPassword = () => setShowOldPassword(!showOldPassword);
    const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword);
    const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    const handleChangePassword = async () => {
        if (newPassword !== confirmPassword) {
            showToast({
                title: 'Password Mismatch',
                description: 'New password and confirm password do not match.',
                status: 'warning',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        setIsLoading(true);

        try {
            const response = await utils.changePassword(userId, oldPassword, newPassword);
            if (response.status === 200) {
                showToast({
                    title: 'Password Changed',
                    description: 'Your password has been successfully updated. Please log in with your new password.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                closeDrawer();
                utils.logout(navigate, showToast, false);
                navigate('/login', { replace: true });
            } else {
                throw new Error('Failed to change password.');
            }
        } catch (error) {
            showToast({
                title: 'Error Changing Password',
                description: error.response?.data?.message || error.message || 'Failed to change password.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        closeDrawer();
    };

    return (
        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                <FormActionLabel formAction="edit" formName="Password"/>
                <SimpleGrid columns={1} gap={4} px={2} py={4}>
                    <GridItem>
                        <FormControl isRequired>
                            <FormLabel>Old Password</FormLabel>
                            <InputGroup>
                                <ThemedStyledInput
                                    type={showOldPassword ? 'text' : 'password'}
                                    placeholder="Enter old password"
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                />
                                <InputRightElement>
                                    <IconButton
                                        aria-label={showOldPassword ? 'Hide password' : 'Show password'}
                                        icon={showOldPassword ? <ViewOffIcon color="white" /> : <ViewIcon color="white" />}
                                        onClick={toggleShowOldPassword}
                                        bg="green.500"
                                        _hover={{ bg: '#000000' }}
                                        _active={{ bg: '#4699bc' }}
                                    />
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                    <GridItem>
                        <FormControl isRequired>
                            <FormLabel>New Password</FormLabel>
                            <Stack direction="row" align="center">
                                <InputGroup>
                                    <ThemedStyledInput
                                        type={showNewPassword ? 'text' : 'password'}
                                        placeholder="Enter new password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                    <InputRightElement>
                                        <IconButton
                                            aria-label={showNewPassword ? 'Hide password' : 'Show password'}
                                            icon={showNewPassword ? <ViewOffIcon color="white" /> : <ViewIcon color="white" />}
                                            onClick={toggleShowNewPassword}
                                            bg="green.500"
                                            _hover={{ bg: '#000000' }}
                                            _active={{ bg: '#4699bc' }}
                                        />
                                    </InputRightElement>
                                </InputGroup>
                                <IconButton
                                    aria-label="Regenerate password"
                                    icon={<RepeatIcon />}
                                    variant="ghost"
                                    onClick={generatePassword}
                                />
                            </Stack>
                        </FormControl>
                    </GridItem>
                    <GridItem>
                        <FormControl isRequired>
                            <FormLabel>Confirm New Password</FormLabel>
                            <InputGroup>
                                <ThemedStyledInput
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    placeholder="Confirm new password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                <InputRightElement>
                                    <IconButton
                                        aria-label={showConfirmPassword ? 'Hide password' : 'Show password'}
                                        icon={showConfirmPassword ? <ViewOffIcon color="white" /> : <ViewIcon color="white" />}
                                        onClick={toggleShowConfirmPassword}
                                        bg="green.500"
                                        _hover={{ bg: '#000000' }}
                                        _active={{ bg: '#4699bc' }}
                                    />
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                    </GridItem>
                </SimpleGrid>
                <Box flexDirection="column" alignItems="left" style={{ alignItems: 'flex-start' }} mt={8} mb={8}>
                    <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
                    <CustomAlert status="warning" message="After updating your password, you will be redirected to log in again. Please keep your new password saved." />
                    <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                        <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                            Cancel
                        </CustomButton>
                        <CustomButton
                            type="submit"
                            onClick={handleChangePassword}
                            isLoading={isLoading}
                            loadingText="Changing..."
                            tooltipLabel="update password"
                        >
                            Change Password
                        </CustomButton>
                    </ButtonStack>
                </Box>
            </VStack>
    );
};

export default AdminPasswordChange;

