import React from 'react';
import { Formik, Form, Field } from 'formik';
import { FormControl, Stack, Select } from '@chakra-ui/react';
import * as Yup from 'yup';
import { CustomButton } from "../../../../components/Styled/StyledButtons";
import { Icon } from "@iconify/react";
import {ThemedStyledInput} from "../../../../components/Styled";
import FieldErrorMessage from "../../../../components/FieldErrorMessage/FieldErrorMessage";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";

const validationSchema = Yup.object({
    fullName: Yup.string()
        .max(50, 'Search & Filters Error: Full Name must be 50 characters or less')
        .trim('Search & Filters Error: Full Name cannot have whitespace')
        .strict(true),
    email: Yup.string()
        .email('Search & Filters Error: Invalid email address, please input a valid email address')
        .max(50, 'Search & Filters Error: Email must be 50 characters or less')
        .trim('Search & Filters Error: Email cannot have whitespace')
        .strict(true),
    phoneNumber: Yup.string()
        .matches(/^[0-9]+$/, 'Search & Filters Error: Phone number must be numeric'),
    status: Yup.string().oneOf(['Pending', 'Reviewed', 'Resolved'], 'Search & Filters Error: Invalid status selection')
});

const MessageSearchFilter = ({ onFilterChange }) => {
    const showToast = useSingleToast();

    return (
        <Formik
            initialValues={{ fullName: '', email: '', phoneNumber: '', status: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                if (!values.fullName && !values.email && !values.phoneNumber && !values.status) {
                    showToast({
                        title: "No Parameters Supplied",
                        description: "Please enter search criteria.",
                        status: "warning",
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    onFilterChange(values);
                }
                setSubmitting(false);
            }}
            onReset={() => {
                onFilterChange({ fullName: '', email: '', phoneNumber: '', status: '' });
            }}
        >
            {({ handleSubmit, resetForm }) => (
                <Form>
                    <Stack spacing={2} direction="row" align="center">
                        {/* Full Name Filter */}
                        <FormControl>
                            <Field as={ThemedStyledInput} id="fullName" name="fullName" type="text" placeholder="Search by Full Name" />
                        </FormControl>

                        {/* Email Filter */}
                        <FormControl>
                            <Field as={ThemedStyledInput} id="email" name="email" type="email" placeholder="Search by Email" />
                        </FormControl>

                        {/* Phone Number Filter */}
                        <FormControl>
                            <Field as={ThemedStyledInput} id="phoneNumber" name="phoneNumber" type="text" placeholder="Search by Phone" />
                        </FormControl>

                        {/* Status Dropdown */}
                        {/*<FormControl>
                            <Field as={Select} id="status" name="status" placeholder="Filter by Status">
                                <option value="Pending">Pending</option>
                                <option value="Reviewed">Reviewed</option>
                                <option value="Resolved">Resolved</option>
                            </Field>
                        </FormControl>*/}

                        {/* Search Button */}
                        <CustomButton onClick={handleSubmit} type="search" tooltipLabel="Search" icon={<Icon icon="bi:search" />} showLabel={false}>
                            Search
                        </CustomButton>

                        {/* Clear Filters Button */}
                        <CustomButton
                            type="reset"
                            onClick={() => {
                                resetForm();
                                onFilterChange({ fullName: '', email: '', phoneNumber: '', status: '' });
                            }}
                            tooltipLabel="Clear Filters"
                            icon={<Icon icon="radix-icons:reset" />}
                            showLabel={false}
                        >
                            Clear
                        </CustomButton>
                    </Stack>
                    {/* Display validation error messages if present */}
                    <FieldErrorMessage name="fullName" />
                    <FieldErrorMessage name="email" />
                    <FieldErrorMessage name="phoneNumber" />
                    <FieldErrorMessage name="status" />
                </Form>
            )}
        </Formik>
    );
};

export default MessageSearchFilter;
