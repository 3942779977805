import React, {useEffect, useState} from "react";
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody } from "@chakra-ui/react";
import PdfViewer from "./PdfViewer";

const PdfViewerModal = ({ filePath, isOpen, onOpen, onClose }) => {
    const [pdfData,setPdfData]=useState(filePath)
    useEffect(() => {
        if(filePath){
            setPdfData(filePath)
        }
    }, [filePath]);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} bg={'gray.700'} size={{base:'full'}} h={'100%'} isCentered>
                <ModalOverlay />
                <ModalContent bg={'#323639'} h={'full'} m={0}>
                    <ModalCloseButton color={'white'} />
                    <ModalBody h={'full'}>
                        <PdfViewer PdfData={pdfData} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default PdfViewerModal;
