import React, {useCallback, useEffect, useState} from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box, Badge,
} from '@chakra-ui/react';
import FaqEditForm from './FaqEditForm';
import {useDrawer} from "../../../../Context/DrawerContext/DrawerContext";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";
import {useError} from "../../../../Context/ErrorContext/ErrorContext";
import {deleteFaq, fetchAllFaqs, fetchFaqById} from "../FaqsAPI";
import {DeleteButton, EditButton, ViewButton} from "../../../../components/Styled/ActionButtons";
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import {StyledTableContainer} from "../../../../components/Styled/StyledComponents";
import PaginationControls from "../../../../Context/PaginationControls/PaginationControls";
import FaqViewForm from "./FaqViewForm";

const FaqTable = ({ faqs, setFaqs, dataLoaded, pageNumber, setPageNumber, totalPages, setTotalPages, setRefreshFlag, filterOptions }) => {
    const { handleError } = useError();
    const { openDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [error, setError] = useState('');
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });


    const handleEdit = (faqId) => {
        openDrawer({
            title: 'Edit FAQ',
            component: FaqEditForm,
            props: {
                faqId: faqId,
                refetchFaqData: refetchFaqData,
                setRefreshFlag: setRefreshFlag,
            },
            key: `edit-${faqId}`,
        }, 'md');
    };

    const handleView = async (faq) => {
        try {
            const faqData = await fetchFaqById(faq._id);
            if (faqData) {
                openDrawer({
                    title: 'View Faq',
                    component: FaqViewForm,
                    props: { formData: faqData },
                    key: `view-${faq._id}`,
                }, 'lg');
            } else {
                throw new Error('Faq data not found.');
            }
        } catch (error) {
            setError(error.message);
            showToast({
                title: 'Error',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };





    const refetchFaqData = useCallback(async (options = {}) => {
        try {
            const data = await fetchAllFaqs(pageNumber, 10, filterOptions, handleError);
            setFaqs(data.faqs);
            setTotalPages(data.totalPages);
        } catch (error) {
            handleError(error.message || "An error occurred.");
        }
    }, [filterOptions, handleError, pageNumber, setFaqs, setTotalPages]);



    useEffect(() => {
        refetchFaqData(filterOptions);
    }, [pageNumber, refetchFaqData, filterOptions, setRefreshFlag]);


    /*if (error) {
        return <Box textAlign="center" my="4">Failed to fetch faq data: {error}</Box>;
    }*/

    if (!faqs) {
        return (
            <Box textAlign="center" my="4" p="4" color="red.600" bg="gray.100">
                <p>Service is down...</p>
            </Box>
        );
    }


    if (error) {
        return (
            <Box textAlign="center" my="4" p="4" color="red.600" bg="yellow.100">
                <p>Error fetching FAQ data:</p>
                <code>{error.toString()}</code>
            </Box>
        );
    }



    if (dataLoaded && Array.isArray(faqs) && faqs.length === 0) {
        return <Box textAlign="center" my="4">No records found.</Box>;
    }

    const handleDelete = (faqId) => {
        setConfirmDialog({
            isOpen: true,
            title: "Confirm Delete",
            message: "Are you sure you want to delete this FAQ? This action cannot be undone.",
            onConfirm: () => confirmDelete(faqId),
        });
    };

    const confirmDelete = async (faqId) => {
        try {
            await deleteFaq(faqId);
            showToast({
                title: "FAQ Deleted",
                description: "Successfully deleted the FAQ.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ isOpen: false });
            setRefreshFlag(prev => !prev);
        } catch (error) {
            showToast({
                title: "Deletion Failed",
                description: error.message || "Failed to delete the FAQ.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            setConfirmDialog({ isOpen: false });
        }
    };



    return (
        <>
            <StyledTableContainer>
                <PaginationControls
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    totalPages={totalPages}
                />
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>#</Th>
                            <Th>Question</Th>
                            <Th>Category</Th>
                            <Th>Status</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>

                    <Tbody>
                        {faqs.map((faq, index) => (
                            <Tr key={index}>
                                <Td>Q0{index + 1}</Td>
                                <Td>{faq.question}</Td>
                                <Td>{faq.category}</Td>
                                <Td>
                                    <Badge variant="solid" colorScheme={faq.published? "green" : "red"}>
                                        {faq.published ? "Published" : "Unpublished"}
                                    </Badge>
                                </Td>
                                <Td>
                                    <ViewButton onClick={() => handleView(faq)} />
                                    <EditButton onClick={() => handleEdit(faq._id)} />
                                    <DeleteButton onClick={() => handleDelete(faq._id)} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </StyledTableContainer>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                title={confirmDialog.title}
                message={confirmDialog.message}
                onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                onConfirm={confirmDialog.onConfirm}
            />
        </>
    );
};

export default FaqTable;
