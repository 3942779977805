import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar'; // Adjust the import path to your Sidebar component

const MainLayout = () => {
  return (
    <Flex> 
      <Box flex="1" p="4">
        <Outlet /> {/* Nested routes will render here */}
      </Box>
    </Flex>
  );
};

export default MainLayout;
