import React,{useState, useEffect} from 'react';
import { Routes as RouterRoutes, Route } from 'react-router-dom';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import Dashboard from '../pages/Dashboard/Dashboard';
import NotFound from "../error/NotFound";
import PrivateRoute from "../components/Private/PrivateRoute";
import Preloader from "../components/Preloader/Preloader";
import Admin from "../pages/PrimaryFeatures/AdminUserManagement/Admin";
import Login from "../pages/Login/Login";
import OTP from "../pages/VerifyOTP/OTP";
import CreatePassword from "../pages/CreatePassword/CreatePassword";
import AuthRouter from "../components/Authentication/AuthRouter";
import VerificationRouter from "../components/Authentication/VerificationRouter";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import Customer from "../pages/CustomerRelated/CustomerUserManagement/Customer";
import Faqs from "../pages/Gallery/Faqs/Faqs";
import {AdminPermissions} from "../pages/PrimaryFeatures/AdminUserManagement/AdminPermissions";
import Orders from "../pages/CustomerRelated/Orders/Orders";
import Notification from "../pages/HelpAndSupport/Notifications/Notification";
import Message from "../pages/HelpAndSupport/Feedback/Message";

const Routes = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000); // 2 seconds delay
    }, []);

    if (isLoading) {
        return <Preloader />;
    }
    return (
        <RouterRoutes>
            {/* Define routes for different pages */}
            <Route element={<AuthRouter />}>
                <Route index element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route element={<VerificationRouter />}>
                    <Route path="/otp" element={<OTP/>} />
                    <Route path="/create-password" element={<CreatePassword />} />
                </Route>
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
            </Route>

            {/* Private Routes */}
            <Route element={<PrivateRoute permissionsRequired={[]}/>}>
                <Route index element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.ADMIN]} />}>
                <Route path="/admin-users" element={<Admin />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.CUSTOMER]} />}>
                <Route path="/customers" element={<Customer />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.ORDER]} />}>
                <Route path="/order-form" element={<Orders />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.NOTIFICATION]} />}>
                <Route path="/notifications" element={<Notification />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.FEEDBACK]} />}>
                <Route path="/feedback" element={<Message />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[AdminPermissions.FAQ]} />}>
                <Route path="/faqs" element={<Faqs />} />
            </Route>
            <Route element={<PrivateRoute permissionsRequired={[]} />}>
                <Route path="/*" element={<NotFound />} />
            </Route>
        </RouterRoutes>
    );
};

export default Routes;
