import React, {useCallback} from 'react';
import {Flex, GridItem} from '@chakra-ui/react';
import CustomerAddForm from './CustomerAddForm';
import { StyledGrid } from "../../../../components/Styled/StyledComponents";
import { ButtonStack, CustomButton } from "../../../../components/Styled/StyledButtons";
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import {Icon} from "@iconify/react";
import AdminSearchFilter from "../../../PrimaryFeatures/AdminUserManagement/Components/AdminSearchFilter";


const CustomerTableTopBar = ({ refetchCustomerData,setPageNumber, setFilterOptions, setFetchTrigger }) => { // Pass refetchCustomerData if needed for the AddForm to trigger a refresh
    const { openDrawer } = useDrawer();

    const handleFilterChange = useCallback(async (newFilterOptions) => {
        setPageNumber(1);
        await new Promise(resolve => setTimeout(resolve, 0));
        setFilterOptions(newFilterOptions);
        setFetchTrigger(f => !f);
    }, [setPageNumber, setFilterOptions, setFetchTrigger]);

    return (
        <>
            <StyledGrid templateColumns="repeat(12, 1fr)" alignItems="center">
                <GridItem colSpan={{ base: 10, md: 11 }}>
                    <AdminSearchFilter onFilterChange={handleFilterChange} />
                </GridItem>
                <GridItem colSpan={{ base: 12, md: 1 }}>
                    <ButtonStack direction="row">
                        <CustomButton
                            type="addNew"
                            showIcon={true}
                            showTooltip={true}
                            tooltipLabel="Add New Customer"
                            icon={<Icon icon="ci:user-add" />}
                            onClick={() => openDrawer({
                                title: 'Add New Customer',
                                component: CustomerAddForm,
                                props: { refetchCustomerData }
                            }, 'md')}
                        >
                            Add New
                        </CustomButton>
                    </ButtonStack>
                </GridItem>
            </StyledGrid>
            <Flex bg="white" mb={4}></Flex>
        </>
    );
};

export default CustomerTableTopBar;
