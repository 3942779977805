import React, {useEffect, useState} from 'react';
import {
  Flex,
  Stack,
  Icon,
  Text,
  Image,
  Box,
  Heading,
  FormControl,
  VStack,
  HStack,
  Link,
  PinInputField, PinInput, Spinner,
} from '@chakra-ui/react';
import EMARKETLogo from '../../../assests/images/emarket-logo.png';
import {  useNavigate } from 'react-router-dom';
import utils from "../../../utils/commonFunctions";
import { LiaUnlockAltSolid } from "react-icons/lia";
import enums from "wuc-library/enums";
import useSingleToast from "../../../hooks/UseSingleToast/UseSingleToast";

const OtpForm = () => {
  const [token, setToken] = useState(localStorage.getItem('userToken'));
  const [email, setEmail] = useState(utils.getUserEmail(token));
  const [otp, setOtp] = useState('');
  const [otpTimer, setOtpTimer] = useState(300);
  const [resendOtpLoading, setResendOtpLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const showToast = useSingleToast();

  useEffect(() => {
    if (otp.length === 6) {
      handleOtpVerification();
    }
  }, [otp]);  // Dependency on otp ensures this runs only when otp changes

  const handleOtpVerification = async () => {
    setLoading(true);
    try {
      const response = await utils.validateOTP(email, otp);
      if (response.status === 200) {
        navigate('/create-password');
        showToast({
          title: 'Verification Successful',
          description: 'You have been successfully verified. Please Reset your password',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error(response.data.message || 'The OTP entered is invalid or has expired. Please try again.');
      }
    } catch (error) {
      showToast({
        title: 'Error',
        description: error.response?.data.message || 'An unexpected error occurred during the verification process. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const requestNewOtp = async () => {
    setResendOtpLoading(true);
    try {
      const response = await utils.requestNewOTP(email, enums.OTPUsage.REGISTRATION);
      if (response.status === 200 || response.data.status === "Success") {
        showToast({
          title: 'New OTP sent',
          description: 'Please check your email for the new OTP.',
          status: 'info',
          duration: 5000,
          isClosable: true,
        });
        // Reset the timer
        setOtpTimer(300);
      } else {
        throw new Error(response.data.message || 'Failed to send new OTP.');
      }
    } catch (error) {
      showToast({
        title: 'Failed to send new OTP',
        description: error.response?.data.message || 'An error occurred. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setResendOtpLoading(false);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    if (otpTimer > 0) {
      const interval = setInterval(() => {
        setOtpTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }
  , [otpTimer]);

  return (
      <>
        <Stack bg='white' h={500} w={1000} rounded='xl' boxShadow='lg' direction={{ base: 'column', md: 'row' }}>
          <Flex p={8} flex={1} align={'center'} justify={'center'}>
            <Stack spacing={4} w={'full'} maxW={'md'}>
              <VStack>
                <Box backgroundColor='green.500' borderRadius='50%' width='100px' height='100px' display='flex' justifyContent='center' alignItems='center'>
                  <Icon as={LiaUnlockAltSolid} color='white' boxSize='50%' />
                </Box>
                <Heading color='black' as='h6' size='lg'>
                  Verify it's you!
                </Heading>
              </VStack>

              <Text fontSize="sm" color="gray.500" textAlign="center">
                We sent a verification code to your email. Enter the code from the email in the field below.
              </Text>

              <FormControl display={'flex'} justifyContent={'center'} id='email'>
                <HStack>
                  <PinInput otp onComplete={setOtp} focusBorderColor='green.500'>
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
              </FormControl>

              <Box>
                <Text fontSize="sm" color="gray.500" textAlign="center">
                  Your OTP expires in {otpTimer > 0 ? ` ${formatTime(otpTimer)} ` : ' 00:00'}
                </Text>
              </Box>

              <Text fontSize="sm" textAlign="center" as="div">
                Need Help?{' '}
                <Link color={loading ? "gray.500" : "gray.1000"} onClick={!loading ? () => window.location.href = 'mailto:contactcenter@wuc.bw' : undefined} _hover={{ textDecoration: loading ? 'none' : 'underline' }} pointerEvents={loading ? 'none' : 'auto'} cursor={loading ? 'not-allowed' : 'pointer'}>
                  Contact Administrator
                </Link>{' '}
                or{' '}
                <Link color={resendOtpLoading ? "gray.500" : "gray.1000"} onClick={!resendOtpLoading ? requestNewOtp : undefined} _hover={{ textDecoration: resendOtpLoading ? 'none' : 'underline' }} pointerEvents={resendOtpLoading ? 'none' : 'auto'} cursor={resendOtpLoading ? 'not-allowed' : 'pointer'}>
                  {resendOtpLoading ? <Spinner size="xs" mr={2} /> : null}
                  Resend OTP
                </Link>
              </Text>
            </Stack>
          </Flex>
          <Flex flex={1}>
            <Image alt={'ResetPassword Image'} src={EMARKETLogo} objectFit={'contain'} h={400} w={400} mt={[20]} ml={[8]} display={{ base: 'none', md: 'flex' }} />
          </Flex>
        </Stack>
      </>
  );
};

export default OtpForm;

