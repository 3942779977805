import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { Center, HStack, Heading, Box } from '@chakra-ui/react'
import CustomBreadcrumb from '../CustomBreadCrumb/CustomBreadCrumb'
import { ContentBox, StyledBox } from "../Styled/StyledComponents";

const DefaultTemplate = ({ Content, Title, icon }) => {
    return (
        <Box bg={'gray.50'}>
            <Header />
            <Center>
                <StyledBox maxW={'8xl'} minH={'100vh'}>
                    <ContentBox >
                        <Heading size={'lg'}>{Title}</Heading>
                        <HStack spacing={6} align="center">
                            <CustomBreadcrumb />
                            {icon}
                        </HStack>
                    </ContentBox>
                    {Content}
                </StyledBox>
            </Center>
            <Footer />
        </Box>
    );
};

export default DefaultTemplate;
