import {API1} from "../../../utils/api";


export const fetchAllMessages = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const data = {
            filterOptions,
            pageNumber,
            pageSize
        };

        const response = await API1.post('/message/get', data);

        if (response.status === 200 && response.data) {
            const { docs: messages, totalDocs } = response.data.data;
            return {
                messages,
                totalPages: Math.ceil(totalDocs / pageSize),
                totalDocs
            };
        } else {
            throw new Error('Unexpected response from the server while fetching messages.');
        }
    } catch (error) {
        console.error("Error fetching messages:", error);
        throw new Error(error?.message || 'Failed to fetch messages. Please try again.');
    }
};






// FetchMessage by ID using query parameters
export const fetchMessageById = async (messageId) => {
    try {
        const filterOptions = {
            messageId,
        };

        const data = {
            filterOptions,
            pageNumber: 1,
            pageSize: 1
        };

        const response = await API1.post('/message/get', data);

        if (response.status === 200 && response.data.data.docs.length > 0) {
            return response.data.data.docs[0];
        } else {
            throw new Error('Message not found.');
        }
    } catch (error) {
        console.error("Error fetching the message details:", error);
        throw new Error(error?.message || 'Failed to fetch the message details.');
    }
};










