import React from "react";
import AuthenticationContainer from "../../components/Authentication/AuthenticationContainer";
import PasswordForm from "./components/CreatePasswordForm";

const CreatePassword = () => {
  return (
    <>
      <AuthenticationContainer Children={<PasswordForm />} />
    </>
  );
};

export default CreatePassword;
