import React, { useEffect, useRef, useState } from 'react';
import {
    FormControl,
    FormLabel,
    GridItem,
    VStack,
    Box,
    SimpleGrid,
    InputRightElement,
    InputGroup,
    IconButton,
    Spinner,
    Textarea,
    Select,
    Input,
    Button,
    useToast,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useDrawer } from "../../../../Context/DrawerContext/DrawerContext";
import useSingleToast from "../../../../hooks/UseSingleToast/UseSingleToast";
import { fetchOrderById, updateOrder } from "../OrderAPI";
import FormActionLabel from "../../../../components/Styled/FormActionLabel";
import CustomAlert from "../../../../components/Styled/StyledAlert";
import { ButtonStack, CustomButton } from "../../../../components/Styled/StyledButtons";
import ConfirmDialog from "../../../../Context/ConfirmDialog/ConfirmDialog";
import { ThemedStyledInput } from "../../../../components/Styled";
import Icon from "../../../../@core/components/icon";
import { API1 } from "../../../../utils/api";
import PdfViewerModal from "../../../../components/PdfViewer/PdfViewerModal";
import utils from "../../../../utils/commonFunctions";
import {fetchAdminByEmail} from "../../../PrimaryFeatures/AdminUserManagement/AdminAPI";  // For fetching user details

const OrderEditForm = ({ orderId, refetchOrderData }) => {
    const { closeDrawer } = useDrawer();
    const showToast = useSingleToast();
    const [fileUrl, setFileUrl] = useState('');
    const [fileName, setFileName] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [initialValues, setInitialValues] = useState({
        fullName: '',
        phoneNumber: '',
        email: '',
        address: '',
        addressDetails: '',
        additionalRequests: '',
        invoice: '',
        status: 'Pending',
    });
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        message: '',
        onConfirm: () => {},
    });

    const hasFetched = useRef(false);
    const [userId, setUserId] = useState(null);  // Store userId from the order

    useEffect(() => {
        const fetchAndSetOrderData = async () => {
            if (hasFetched.current) return;

            try {
                const orderData = await fetchOrderById(orderId);
                if (orderData) {
                    setInitialValues({
                        fullName: orderData.fullName,
                        phoneNumber: orderData.phoneNumber,
                        email: orderData.email,
                        address: orderData.address,
                        addressDetails: orderData.addressDetails,
                        additionalRequests: orderData.additionalRequests,
                        invoice: orderData.invoice,
                        status: orderData.status,
                    });
                    setUserId(orderData.userId);  // Set userId from order data
                    setFileUrl(orderData.invoice);
                    setFileName(orderData.invoice.split('/').pop());
                }
                hasFetched.current = true;
            } catch (error) {
                showToast({
                    title: 'Error fetching order data',
                    description: error.message || 'An unexpected error occurred.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        fetchAndSetOrderData();
    }, [orderId, showToast]);

    const orderSchema = Yup.object().shape({
        fullName: Yup.string().required('Full name is required.'),
        phoneNumber: Yup.string()
            .matches(/^[0-9]+$/, "Phone number is not valid")
            .min(7, 'Phone number must be at least 7 digits')
            .max(15, 'Phone number must not exceed 15 digits')
            .required('Phone number is required.'),
        email: Yup.string().email('Invalid email address.').required('Email is required.'),
        address: Yup.string().required('Address is required.'),
        addressDetails: Yup.string(),
        additionalRequests: Yup.string(),
        status: Yup.string().required('Status is required.'),
        invoice: Yup.string().required('Invoice is required.'),
    });


    const handleSubmit = async (values, actions) => {
        try {
            // Fetch the token from localStorage
            const tokenWithBearer = localStorage.getItem("authToken");
            if (tokenWithBearer) {
                const token = tokenWithBearer.split(' ')[1];

                // Fetch the email from the token
                const email = utils.getEmail(token);
                if (!email) {
                    throw new Error("Admin email not found.");
                }

                // Fetch the admin data using the email
                const adminData = await fetchAdminByEmail(email);
                if (!adminData || (!adminData.firstName && !adminData.lastName)) {
                    throw new Error("Admin data not found.");
                }

                // Construct full name from firstName and lastName
                const updatedBy = `${adminData.firstName} ${adminData.lastName}`.trim();

                // Prepare payload
                const updatedValues = {
                    ...values,
                    userId,
                    updatedBy,
                };

                // Update the order
                await updateOrder(orderId, updatedValues);

                // Show success message
                showToast({
                    title: 'Order updated successfully',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });

                // Refetch order data and close drawer
                refetchOrderData();
                closeDrawer();
            } else {
                throw new Error("Authentication token not found.");
            }
        } catch (error) {
            showToast({
                title: 'Error updating order',
                description: error.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            actions.setSubmitting(false);
        }
    };




    const handleCancel = () => {
        setConfirmDialog({
            isOpen: true,
            title: "Cancel Confirmation",
            message: "Are you sure you want to cancel? Any unsaved changes will be lost.",
            onConfirm: () => {
                closeDrawer();
                setConfirmDialog({ ...confirmDialog, isOpen: false });
            }
        });
    };

    return (
        <>
            <FormActionLabel formAction="edit" formName="Order" />
            <Formik
                initialValues={initialValues}
                validationSchema={orderSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ setFieldValue, ...formikProps }) => (
                    <Form>
                        <VStack spacing={4} align="stretch" marginY={2} marginX={8}>
                            <SimpleGrid columns={1} gap={4} px={2} py={4}>
                                <FieldControl
                                    formikProps={formikProps}
                                    name="fullName"
                                    label="Full Name :"
                                    placeholder="Enter full name"
                                    icon={<Icon icon="mdi:rename-outline" />}
                                />
                                <FieldControl
                                    formikProps={formikProps}
                                    name="phoneNumber"
                                    label="Phone Number :"
                                    placeholder="Enter phone number"
                                    icon={<Icon icon="mdi:phone-outline" />}
                                />
                                <FieldControl
                                    formikProps={formikProps}
                                    name="email"
                                    label="Email :"
                                    placeholder="Enter email"
                                    type="email"
                                    icon={<Icon icon="mdi:email-outline" />}
                                    isReadOnly  // Make email read-only
                                />
                                {/*<FieldControl
                                    formikProps={formikProps}
                                    name="address"
                                    label="Address :"
                                    placeholder="Enter address"
                                    icon={<Icon icon="mdi:home-outline" />}
                                />*/}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.addressDetails && formikProps.touched.addressDetails}>
                                        <FormLabel htmlFor="addressDetails">Address Details :</FormLabel>
                                        <Field name="addressDetails">
                                            {({ field }) => (
                                                <Textarea
                                                    {...field}
                                                    placeholder="Additional address details"
                                                />
                                            )}
                                        </Field>
                                        {formikProps.errors.addressDetails && formikProps.touched.addressDetails && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.addressDetails}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>

                                {/* Additional Requests Field */}
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.additionalRequests && formikProps.touched.additionalRequests}>
                                        <FormLabel htmlFor="additionalRequests">Additional Requests :</FormLabel>
                                        <Field name="additionalRequests">
                                            {({ field }) => (
                                                <Textarea
                                                    {...field}
                                                    placeholder="Any additional requests"
                                                />
                                            )}
                                        </Field>
                                        {formikProps.errors.additionalRequests && formikProps.touched.additionalRequests && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.additionalRequests}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>

                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.status && formikProps.touched.status}>
                                        <FormLabel htmlFor="status">Status :</FormLabel>
                                        <Field name="status">
                                            {({ field }) => (
                                                <Select {...field} placeholder="Select status">
                                                    <option value="Pending">Pending</option>
                                                    <option value="Processing">Processing</option>
                                                    <option value="Completed">Completed</option>
                                                    <option value="Cancelled">Cancelled</option>
                                                </Select>
                                            )}
                                        </Field>
                                        {formikProps.errors.status && formikProps.touched.status && (
                                            <Text color="red.500" fontSize="sm">{formikProps.errors.status}</Text>
                                        )}
                                    </FormControl>
                                </GridItem>
                                <GridItem>
                                    <FormControl isInvalid={formikProps.errors.invoice && formikProps.touched.invoice}>
                                        <FormLabel htmlFor="invoice">Invoice :</FormLabel>
                                        <Field name="invoice">
                                            {({ form }) => (
                                                <>
                                                    {/*<Input id="fileInput" type="file" disabled />*/}
                                                    {fileUrl && (
                                                        <Box mt={2}>
                                                            <Button onClick={onOpen} colorScheme="red" size="xs">
                                                                Preview Invoice
                                                            </Button>
                                                        </Box>
                                                    )}
                                                </>
                                            )}
                                        </Field>
                                    </FormControl>
                                </GridItem>
                            </SimpleGrid>

                            <Box flexDirection="column" alignItems="left" mt={8} mb={8}>
                                <CustomAlert status="warning" message="If you want to abort the action, please use the Cancel button." />
                                <CustomAlert status="warning" message="Note: Customer's Email Address & Invoice cannot be updated. 🙂" />
                                <ButtonStack direction="row" style={{ justifyContent: 'flex-start' }}>
                                    <CustomButton onClick={handleCancel} type="cancel" showIcon={false}>
                                        Cancel
                                    </CustomButton>
                                    <CustomButton
                                        type="submit"
                                        disabled={formikProps.isSubmitting}
                                        style={{
                                            opacity: formikProps.isSubmitting ? 0.7 : 1,
                                            pointerEvents: formikProps.isSubmitting ? 'none' : 'auto',
                                        }}
                                    >
                                        {formikProps.isSubmitting ? (
                                            <>
                                                <Spinner size="xs" mr={2} />
                                                Updating...
                                            </>
                                        ) : 'Update'}
                                    </CustomButton>
                                </ButtonStack>
                            </Box>
                        </VStack>
                        <PdfViewerModal filePath={fileUrl} isOpen={isOpen} onClose={onClose} />
                    </Form>
                )}
            </Formik>
            <ConfirmDialog
                isOpen={confirmDialog.isOpen}
                onClose={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                onConfirm={confirmDialog.onConfirm}
                title={confirmDialog.title}
                message={confirmDialog.message}
            />
        </>
    );
};

const FieldControl = ({ formikProps, name, label, placeholder, icon, type = "text", isReadOnly = false }) => (
    <GridItem>
        <FormControl isInvalid={formikProps.errors[name] && formikProps.touched[name]}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <InputGroup>
                <ThemedStyledInput
                    {...formikProps.getFieldProps(name)}
                    id={name}
                    placeholder={placeholder}
                    type={type}
                    isReadOnly={isReadOnly} // Make input read-only if specified
                />
                {icon && (
                    <InputRightElement>
                        <IconButton
                            aria-label="Icon button label"
                            bg="green.500"
                            _hover={{ bg: '#000000' }}
                            _active={{ bg: '#4699bc' }}
                            color="white"
                        >
                            {icon}
                        </IconButton>
                    </InputRightElement>
                )}
            </InputGroup>
            {formikProps.errors[name] && formikProps.touched[name] && (
                <Text color="red.500" fontSize="sm">{formikProps.errors[name]}</Text>
            )}
        </FormControl>
    </GridItem>
);

export default OrderEditForm;
