import React, { useEffect, useState, useCallback } from 'react';
import {Box} from "@chakra-ui/react";
import {useError} from "../../../../Context/ErrorContext/ErrorContext";
import {fetchAllOrders} from "../OrderAPI";
import OrderTableTopBar from "./OrderTableTopBar";
import OrderTable from "./OrderTable";

const OrderContainer = () => {
    const [orders, setOrders] = useState([]);
    const [filterOptions, setFilterOptions] = useState({});
    const [error, setError] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const { handleError } = useError();

    const refetchOrderData = useCallback(async (options = {}) => {
        setDataLoaded(false);
        try {
            const data = await fetchAllOrders(pageNumber, 10, filterOptions, handleError);
            setOrders(data.orders);
            setTotalPages(data.totalPages);
            setError('');
            setDataLoaded(true);
        } catch (error) {
            setError(error.message);
            setOrders([]);
            setTotalPages(0);
        }
    }, [pageNumber, filterOptions, handleError]);

    useEffect(() => {
        refetchOrderData();
    }, [fetchTrigger, refetchOrderData, refreshFlag, totalPages, filterOptions]);



    /*if (!orders || orders.length === 0) {
        return (
            <Box textAlign="center" my="4" p="4" color="red.600" bg="gray.100">
                <p>No orders found or service is down...</p>
            </Box>
        );
    }*/


    return (
        <>
            <OrderTableTopBar
                refetchOrderData={refetchOrderData}
                setPageNumber={setPageNumber}
                setFilterOptions={setFilterOptions}
                setFetchTrigger={setFetchTrigger}
            />
            <OrderTable
                orders={orders}
                setOrders={setOrders}
                error={error}
                pageNumber={pageNumber}
                setRefreshFlag={setRefreshFlag}
                setPageNumber={setPageNumber}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                filterOptions={filterOptions}
                dataLoaded={dataLoaded}
                refetchOrderData={refetchOrderData}
            />
        </>
    );
};

export default OrderContainer;
