import React, { useState, useEffect } from 'react';
import {
  useColorModeValue,
  useDisclosure,
  chakra,
  Button,
  IconButton,
  Image,
  Link,
  Popover,
  PopoverTrigger,
  HStack,
} from '@chakra-ui/react';
import { AiFillHome, AiOutlineInbox, AiOutlineMenu } from 'react-icons/ai';
import EMARKETLogo from '../../assests/images/emarket-logo.png';
import FeatureSections from "./FeatureSections";
import { useNavigate } from "react-router-dom";
import utils from "../../utils/commonFunctions";
import { fetchAdminByEmail } from "../../pages/PrimaryFeatures/AdminUserManagement/AdminAPI";
import useSingleToast from "../../hooks/UseSingleToast/UseSingleToast";
import {
  IconBox, MobileNav,
  NavBarContainer,
  SectionLink,
  StyledHeader,
  StyledPopoverContent
} from "../Styled/HeaderAndFooterStyles";
import {ButtonStack, CustomButton} from "../Styled/StyledButtons";
import {useDrawer} from "../../Context/DrawerContext/DrawerContext";
import AdminProfileView from "./Components/AdminProfileView";
import AdminPasswordChange from "./Components/AdminPasswordChange";

const Header = () => {
  const { openDrawer } = useDrawer();
  const [isScrolled, setIsScrolled] = useState(false);
  const [adminPermissions, setAdminPermissions] = useState([]);
  const [adminData, setAdminData] = useState(null);
  const bg = useColorModeValue('white', 'gray.800');
  const navigate = useNavigate();
  const mobileNav = useDisclosure();
  const showToast = useSingleToast();
  const [activeSectionKey, setActiveSectionKey] = useState(null);
  const closeAllPopovers = () => setActiveSectionKey(null);

  useEffect(() => {
    // Close popovers on outside clicks
    const handleBodyClick = (event) => {
      if (!event.target.closest('.popover-trigger')) {
        closeAllPopovers();
      }
    };

    document.body.addEventListener('click', handleBodyClick);
    return () => document.body.removeEventListener('click', handleBodyClick);
  }, []);

  const togglePopover = (key) => {
    setActiveSectionKey(activeSectionKey === key ? null : key);
  };


  useEffect(() => {
    async function fetchAdminPermissions() {
      const tokenWithBearer = localStorage.getItem("authToken");
      if (tokenWithBearer) {
        const token = tokenWithBearer.split(' ')[1];
        const email = utils.getEmail(token);
        if (email) {
          try {
            const adminData = await fetchAdminByEmail(email);
            console.log('adminData from Headers: ' + adminData)
            setAdminPermissions(adminData.permissions || []);
            setAdminData(adminData);
          } catch (error) {
            console.error('Error fetching admin data:', error);
          }
        } else {
          navigate('/login');
        }
      }
    }

    fetchAdminPermissions();
  }, [navigate]);

  const handleLogout = () => {
    utils.logout(navigate, showToast, true);
  };

  const goToDashboard = () => {
    navigate('/dashboard');
  };

  const handleViewProfile = () => {
    openDrawer({
      title: 'View Admin',
      component: AdminProfileView,
      props: { formData: adminData },
    }, 'xl');
  };

  const handleAdminChangePassword = () => {
    openDrawer({
      title: 'Edit Password',
      component: AdminPasswordChange,
      props: {
        userId: adminData._id,
      }
    }, 'md');
  };

  const iconStyle = {
    size: 24,
    color: '#59E81C'
  };

  const renderIcon = (IconComponent) => <IconComponent size={iconStyle.size} color={iconStyle.color} />;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const renderFeatureSections = () => {
    return FeatureSections.map((section) => {
      const sectionAccess = section.links.some(link =>
          link.permissionsRequired.some(perm => adminPermissions.includes(perm))
      );

      if (sectionAccess) {
        const isOpen = activeSectionKey === section.key;

        return (
            <Popover
                key={section.key}
                isOpen={isOpen}
                onClose={() => setActiveSectionKey(null)}
                closeOnBlur={true}
            >
              <PopoverTrigger>
                <Button
                    className="popover-trigger"
                    rightIcon={<section.icon />}
                    variant="ghost"
                    onClick={() => togglePopover(section.key)}
                >
                  {section.title}
                </Button>
              </PopoverTrigger>
              <StyledPopoverContent bg={bg}>
                {section.links.map((link, index) => {
                  const linkPermission = link.permissionsRequired.some(perm => adminPermissions.includes(perm));
                  if (linkPermission) {
                    return (
                        <SectionLink key={index} href={link.link || '#'} hbg="#EDF2F7">
                          <IconBox>{renderIcon(link.icon)}</IconBox>
                          <chakra.div ml="4">
                            <chakra.p fontSize="sm" fontWeight="700">{link.title}</chakra.p>
                            <chakra.p mt="1" fontSize="sm">{link.description}</chakra.p>
                          </chakra.div>
                        </SectionLink>
                    );
                  }
                  return null;
                })}
                <ButtonStack direction="row">
                  <HStack className="bottom-buttons-container">
                    <CustomButton
                        type="home"
                        showTooltip={true}
                        showLabel={false}
                        onClick={() => navigate('/dashboard')}
                    >
                      Home
                    </CustomButton>
                    <CustomButton
                        type="logout"
                        showTooltip={true}
                        showLabel={false}
                        onClick={() => utils.logout(navigate, showToast, true)}
                    >
                      Logout
                    </CustomButton>
                  </HStack>
                </ButtonStack>
              </StyledPopoverContent>
            </Popover>
        );
      } else {
        return (
            <Button key={section.key} rightIcon={<section.icon />} variant="ghost" isDisabled>
              {section.title}
            </Button>
        );
      }
    });
  };





  return (
      <StyledHeader bg={bg}>
        <NavBarContainer>
          <Link href="/">
            <Image src={EMARKETLogo} h={isScrolled ? "65px" : "65px"} alt="EMARKET Logo" />
          </Link>
          <HStack spacing={4} display={{ base: 'none', md: 'flex' }} >
            {renderFeatureSections()}
          </HStack>
          <HStack spacing={4} display={{ base: 'none', md: 'flex' }} >
            <ButtonStack direction="row">
              <HStack>
                <CustomButton
                    type="home"
                    showTooltip={true}
                    showLabel={false}
                    onClick={goToDashboard}
                >
                  Home
                </CustomButton>
                <CustomButton
                    onClick={handleViewProfile}
                    type="viewProfile"
                    tooltipLabel="My Profile"
                    showLabel={false}
                >
                  View Profile
                </CustomButton>
                <CustomButton
                    onClick={handleAdminChangePassword}
                    type="resetPassword"
                    tooltipLabel="Change Password"
                    showLabel={false}
                >
                  Reset Password
                </CustomButton>
                <CustomButton
                    type="logout"
                    showTooltip={true}
                    showLabel={false}
                    onClick={handleLogout}
                >
                  Logout
                </CustomButton>
              </HStack>
            </ButtonStack>
          </HStack>
          <IconButton
              icon={<AiOutlineMenu />}
              aria-label="Open menu"
              display={{ base: 'inline-flex', md: 'none' }}
              onClick={mobileNav.onOpen}
          />
        </NavBarContainer>
        {mobileNav.isOpen && (
            <MobileNav bg={bg} onClose={mobileNav.onClose}>
              <Button w="full" variant="ghost" leftIcon={<AiFillHome />}>Dashboard</Button>
              <Button w="full" variant="solid" colorScheme="brand" leftIcon={<AiOutlineInbox />}>Inbox</Button>
              {/* More mobile navigation buttons if needed */}
            </MobileNav>
        )}
      </StyledHeader>
  );
};

export default Header;
