import React from 'react'
import CustomerUserContainer from './Components/CustomerContainer'
import {Icon} from "@iconify/react";
import DefaultTemplate from "../../../components/DefaultTemplate/DefaultTemplate";

const Customer = () => {
    return (
        <>
            <DefaultTemplate Content={ <CustomerUserContainer/> } Title={"Customers" } icon={<Icon icon="fa-solid:users" />}/>
        </>
    )
}

export default Customer
