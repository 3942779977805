import React from 'react';
import {Flex, Heading, HStack, Text} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {ButtonStack, CustomButton} from "../components/Styled/StyledButtons";
import utils from "../utils/commonFunctions";

function NotFound() {
    // Initialize navigate function from useNavigate hook
    const navigate = useNavigate();

    // Function to navigate to the dashboard
    const goToDashboard = () => {
        navigate('/dashboard'); // Redirect to the dashboard page
    };

    const handleLogout = () => {
        utils.logout(navigate('/login')); // Pass the navigate function to utils.logout
    };

    return (
        <Flex
            height="100vh"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
        >
            <Heading fontSize="12rem">404</Heading>
            <Text mb={8}>You seem to have wandered off. Let's get you back.</Text>
            <HStack spacing={4} display={{ base: 'none', md: 'flex' }}>
                <ButtonStack direction="row">
                    <HStack>
                        <CustomButton
                            type="home"
                            showTooltip={true}
                            onClick={goToDashboard}
                        >
                            Home
                        </CustomButton>
                        <CustomButton
                            type="logout"
                            showTooltip={true}
                            onClick={handleLogout}
                        >
                            Logout
                        </CustomButton>
                    </HStack>
                </ButtonStack>
            </HStack>
        </Flex>
    );
}

export default NotFound;
