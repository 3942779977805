import {API1} from "../../../utils/api";

export const createOrder = async (orderData) => {
    try {
        const response = await API1.post('/orders/add', orderData);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "An unexpected error occurred while creating the Order.");
    }
};


export const fetchAllOrders = async (pageNumber = 1, pageSize = 10, filterOptions = {}) => {
    try {
        const data = {
            filterOptions,
            pageNumber,
            pageSize
        };

        const response = await API1.post('/orders/get', data);
        if (response.status === 200) {
            const { docs: orders, totalDocs } = response.data.data;
            return {
                orders,
                totalPages: Math.ceil(totalDocs / pageSize),
            };
        } else {
            throw new Error('Unexpected response from the server while fetching orders.');
        }
    } catch (error) {
        console.error("Error fetching orders:", error);
        throw new Error('Failed to fetch orders. Please try again.');
    }
};



// Fetch Order by ID using query parameters
/*export const fetchOrderById = async (orderId) => {
    try {
        const queryParams = new URLSearchParams({ id: orderId }).toString();
        const response = await API1.get(`/orders/get?${queryParams}`);
        if (response.status === 200 && response.data.docs.length > 0) {
            return response.data.data.docs[0];
        } else {
            throw new Error('Order not found.');
        }
    } catch (error) {
        throw new Error(error?.message || "Failed to fetch the Order details.");
    }
};*/


export const fetchOrderById = async (orderId) => {
    try {
        const data = {
            filterOptions: { id: orderId },
            pageNumber: 1,
            pageSize: 1
        };

        const response = await API1.post('/orders/get', data);
        if (response.data.status === 'Success' && response.data.data.docs.length > 0) {
            const orderData = response.data.data.docs[0];
            return orderData;
        } else {
            throw new Error('Order not found.');
        }
    } catch (error) {
        throw error;
    }
};

// Update Order
export const updateOrder = async (orderId, orderData) => {
    try {
        const response = await API1.put(`/orders/update/${orderId}`, orderData);
        if (response.status !== 200) {
            throw new Error('Update failed due to server response');
        }
        return response.data;
    } catch (error) {
        console.error("Error updating Order:", error);
        throw new Error(error?.message || "An error occurred while updating the Order.");
    }
};




// Delete Order
export const deleteOrder = async (orderId) => {
    try {
        const response = await API1.delete(`/orders/delete/${orderId}`);
        return response.data;
    } catch (error) {
        throw new Error(error?.message || "Failed to delete the Order");
    }
};
